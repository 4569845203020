import { ServiceBase } from './service-base';
import { UserDataResult } from './user-data-types';

export class UserService extends ServiceBase {
  constructor(env: string) {
    super(env);
  }

  public fetchUserByUsername(username: string) {
    const requestUrl = this.createEndpointUrl(`users/simple`, {
      filter: `userId:eq_case_insensitive:${username}`,
      fields: 'groups',
    });
    return this.performApiRequest<UserDataResult>(requestUrl, 'GET');
  }
}
