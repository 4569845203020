import { useAuth } from '@myosh/myosh-login';
import { AboutInfoModal } from '@myosh/odin-components';
import { useGetEnvironmentQuery } from '../../api/generated/v4-api';

interface AboutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AboutModal = ({ isOpen, onClose }: AboutModalProps) => {
  const { data: schemaInfo } = useGetEnvironmentQuery();
  const { logout } = useAuth();

  const handleLogOut = () => {
    logout();
    localStorage.clear();
  };

  return (
    <AboutInfoModal
      shouldCheckZIndex
      build={process.env.REACT_APP_VERSION}
      currentSchema={{ text: schemaInfo?.schema ?? 'n/a' }}
      isAboutInfoOpen={isOpen}
      showLogoutButton={true}
      onClose={onClose}
      onLogout={handleLogOut}
      userTimeZone={schemaInfo?.['time-zone']}
      {...schemaInfo}
    />
  );
};

export default AboutModal;
