import { BowtieStateCauseData, BowtieStateData } from '../../../../services/bowtie-data-types';
import { ContainerType } from '../../../controls.component';
import GeneralContainer from '../general-container';
import MultiLineContainer, { CONTAINER_ALIGNMENT } from '../multi-line-container/multi-line-container';

interface PreventativeControlsExistingProps {
  bowtieData: BowtieStateData;
}

const PreventativeControlsExisting = ({ bowtieData }: PreventativeControlsExistingProps) => {
  const preventativeControlsLines = bowtieData.causes
    ? bowtieData.causes.map((cause: BowtieStateCauseData, index) => {
        return {
          id: `${cause.uuid}_${index}`,
          childControls: cause.preventativeControls,
          parentId: cause.uuid,
        };
      })
    : [];

  return (
    <GeneralContainer id="preventative-controls-container" type={ContainerType.PREVENTATIVE_CONTROLS}>
      <MultiLineContainer
        id="preventative_control"
        type={ContainerType.PREVENTATIVE_CONTROLS}
        mode="multi"
        controlLines={preventativeControlsLines}
        form={bowtieData.bowtieConfiguration.forms.controls}
        alignment={CONTAINER_ALIGNMENT.RIGHT}
      />
    </GeneralContainer>
  );
};

export default PreventativeControlsExisting;
