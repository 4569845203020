import { Button } from '@myosh/odin-components';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BowtieSuggestPostApiArg, ControlType, useBowtieSuggestPostMutation } from '../../api/generated/ai-api';
import { cn } from '../../helpers/util';
import diagramSelectors from '../../modules/diagram/diagramSelectors';
import { useAppSelector } from '../../modules/hooks';
import {
  BowtieCauseRecord,
  BowtieConsequenceRecord,
  BowtieStateCauseData,
  BowtieStateConsequenceData,
  BowtieStateData,
} from '../../services/bowtie-data-types';

enum ControlCategory {
  SOFT = 'Soft',
  HARD = 'Hard',
}

const generateSuggestPayload = (bowtieData: BowtieStateData, term?: string): BowtieSuggestPostApiArg => {
  const causes = bowtieData?.causes?.map((cause: BowtieStateCauseData) => {
    return {
      id: String(cause.id),
      name: cause.value,
      controls: cause.preventativeControls.map((preventativeControl: BowtieCauseRecord) => {
        return {
          id: String(preventativeControl.id),
          isCritical: preventativeControl.criticalOrNonCritical !== 'Non Critical',
          isGlobal: preventativeControl.global as boolean,
          name: preventativeControl.value,
          type: 'Preventative' as ControlType,
          isInitial: false,
          category: ControlCategory.SOFT,
        };
      }),
    };
  });

  const consequences = bowtieData?.consequences?.map((consequence: BowtieStateConsequenceData) => {
    return {
      id: String(consequence.id),
      name: consequence.value,
      controls: consequence.mitigatingControls.map((mitigatingControl: BowtieConsequenceRecord) => {
        return {
          id: String(mitigatingControl.id),
          isCritical: mitigatingControl.criticalOrNonCritical !== 'Non Critical',
          isGlobal: mitigatingControl.global as boolean,
          name: mitigatingControl.value,
          type: 'Mitigating' as ControlType,
          isInitial: false,
          category: ControlCategory.SOFT,
        };
      }),
    };
  });

  return {
    bowtieCorrectionRequest: {
      bowtie: {
        risk: bowtieData.scenario,
        causes: causes || [],
        consequences: consequences || [],
        hazard: bowtieData.hazard ?? '',
        term: term ?? '',
      },
      control_types: [''],
    },
  };
};

type AIAnalysisSuggestionButtonProps = {
  handleClickAIAnalysisButton: (text: string) => void;
};

function AIAnalysisSuggestionButton({ handleClickAIAnalysisButton }: AIAnalysisSuggestionButtonProps) {
  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);
  const { search } = useLocation();
  const loadingDiagram = useAppSelector(diagramSelectors.selectLoading);

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [getSuggestion, getSuggestionResult] = useBowtieSuggestPostMutation();
  const loadingAnalysis = getSuggestionResult?.isLoading;

  const loading = loadingDiagram || loadingAnalysis;

  const handleBowtieAIAnalysisClick = useCallback(() => {
    getSuggestion(generateSuggestPayload(bowtieData, searchParams.get('term') as string))
      .unwrap()
      .then((data) => handleClickAIAnalysisButton(data?.suggestion_text))
      .catch(() => toast.error('Unable to perform analysis, please try again.'));
  }, [bowtieData]);

  return (
    <>
      <Button variant="alternative" disabled={loading} onClick={handleBowtieAIAnalysisClick} loading={loadingAnalysis}>
        <p className={cn({ 'bt-ml-1': loading }, { 'bt-ml-0': !loading })}>AI Analysis</p>
      </Button>
    </>
  );
}

export default AIAnalysisSuggestionButton;
