const env = process.env;

export interface EndPointSettings {
  urlTemplate: string;
  endpointBaseUrl?: string;
  apiKey?: string;
}

export const keys: Record<string, EndPointSettings> = {
  test: {
    urlTemplate: ':endpointBaseUrl/:endpointVersion',
    endpointBaseUrl: env.REACT_APP_API_V4,
    apiKey: env.REACT_APP_API_KEY,
  },
};
