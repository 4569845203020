import { configureStore } from '@reduxjs/toolkit';
import { enhancedAiApi as aiApi } from '../api/enhanced/enhanced-ai-api';
import { enhancedV4Api as v4Api } from '../api/enhanced/enhanced-v4-api';
import diagramReducer from './diagram/diagramReducer';
import filterReducer from './filter/filterReducer';
import userReducer from './user/userReducer';

const store = configureStore({
  reducer: {
    [aiApi.reducerPath]: aiApi.reducer,
    [v4Api.reducerPath]: v4Api.reducer,
    diagram: diagramReducer,
    filter: filterReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(aiApi.middleware).concat(v4Api.middleware),
});

export default store;
