import { v4 as uuid4, v4 } from 'uuid';
import { isErrorResponse, isObject, isSuccessResponse } from '../../helpers/type-guards';
import { BowtieService } from '../../services/bowtie-service';
import { RecordService } from '../../services/record-service';
import DiagramService from './diagramService';
import {
  DIAGRAM_ADD_CAUSE_FAIL,
  DIAGRAM_ADD_CAUSE_INPUT,
  DIAGRAM_ADD_CAUSE_START,
  DIAGRAM_ADD_CAUSE_SUCCESS,
  DIAGRAM_ADD_CONSEQUENCE_FAIL,
  DIAGRAM_ADD_CONSEQUENCE_INPUT,
  DIAGRAM_ADD_CONSEQUENCE_START,
  DIAGRAM_ADD_CONSEQUENCE_SUCCESS,
  DIAGRAM_ADD_HAZARD_FAIL,
  DIAGRAM_ADD_HAZARD_START,
  DIAGRAM_ADD_HAZARD_SUCCESS,
  DIAGRAM_ADD_MITIGATING_CONTROLS_FAIL,
  DIAGRAM_ADD_MITIGATING_CONTROLS_INPUT,
  DIAGRAM_ADD_MITIGATING_CONTROLS_START,
  DIAGRAM_ADD_MITIGATING_CONTROLS_SUCCESS,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_FAIL,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_INPUT,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_START,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_SUCCESS,
  DIAGRAM_ADD_RISK_SCENARIO_FAIL,
  DIAGRAM_ADD_RISK_SCENARIO_START,
  DIAGRAM_FETCH_RECORD_FAIL,
  DIAGRAM_FETCH_RECORD_START,
  DIAGRAM_FETCH_RECORD_SUCCESS,
  DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL,
  DIAGRAM_FETCH_RECORDS_BY_FORM_START,
  DIAGRAM_FETCH_RECORDS_BY_FORM_SUCCESS,
  DIAGRAM_FETCH_SCENARIO_RECORD_FAIL,
  DIAGRAM_FETCH_SCENARIO_RECORD_START,
  DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS,
  DIAGRAM_LAYOUT,
  DIAGRAM_QUEUE_ADD,
  DIAGRAM_QUEUE_ADD_FAILED,
  DIAGRAM_QUEUE_FAIL,
  DIAGRAM_QUEUE_STATUS,
  DIAGRAM_QUEUE_UPDATE,
  DIAGRAM_QUEUE_UPDATE_FAILED,
  DIAGRAM_REMOVE_CAUSE,
  DIAGRAM_REMOVE_CONSEQUENCE,
  DIAGRAM_REMOVE_MITIGATING_CONTROL,
  DIAGRAM_REMOVE_PREVENTATIVE_CONTROL,
  DIAGRAM_RESET_FORM_RECORDS,
  DIAGRAM_SET_BOWTIE_DATA_LOADING,
  DIAGRAM_UPDATE_BOWTIE_SCENARIO,
  DIAGRAM_UPDATE_BOWTIE_SCENARIO_FAIL,
  DIAGRAM_UPDATE_BOWTIE_SCENARIO_SUCCESS,
  DIAGRAM_UPDATE_CAUSE,
  DIAGRAM_UPDATE_CONSEQUENCE,
  DIAGRAM_UPDATE_MITIGATING_CONTROL,
  DIAGRAM_UPDATE_PREVENTATIVE_CONTROL,
} from './diagramTypes';

const riskScenarioPageSize = 50;

const diagramService = new DiagramService();
const recordService = new RecordService('test');
const bowtieService = new BowtieService('test');

const doResetFormRecords = () => async (dispatch) => {
  dispatch({ type: DIAGRAM_RESET_FORM_RECORDS });
  dispatch(
    doUpdateDiagramLayout({
      isWidth: true,
    })
  );
};

const doFetchRecordsByFormId = (formId, query) => async (dispatch) => {
  try {
    dispatch({
      type: DIAGRAM_FETCH_RECORDS_BY_FORM_START,
    });

    const recordsPayload = await recordService.fetchFilteredRecordsByFormId(formId, query.filter);

    if (isSuccessResponse(recordsPayload)) {
      const items = recordsPayload.payload.data?.result?.results;
      dispatch({
        type: DIAGRAM_FETCH_RECORDS_BY_FORM_SUCCESS,
        payload: items,
      });
    } else {
      dispatch({
        type: DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL,
        payload: 'Internal Server Error',
      });
    }
  } catch (err) {
    dispatch({
      type: DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL,
      payload: err.message,
    });
  }
};

const doAddScenarioAndTransformRecord = (params) => async (dispatch, getState) => {
  const { riskScenarioSavedRecordPayload: recordPayload, addHazard, bowtieConfiguration } = params;

  try {
    const bowtieDataState = getState().diagram.bowtieData;

    if (!recordPayload.success) {
      dispatch({
        type: DIAGRAM_FETCH_RECORD_FAIL,
        payload: recordPayload.payload.message,
      });
    } else {
      const record = recordPayload?.payload?.data?.result?.results?.[0];

      dispatch({
        type: DIAGRAM_FETCH_RECORD_START,
        payload: 'Fetching Bowtie Configuration',
      });
      dispatch({
        type: DIAGRAM_FETCH_SCENARIO_RECORD_START,
        payload: 'Fetching Scenario Records',
      });

      try {
        diagramService
          .doFilterScenarioRecord(record, bowtieConfiguration, true)
          .then((records) => {
            let allRecords = [...(bowtieDataState?.diagram?.riskScenarioRecords?.records ?? [])];
            allRecords[0] = records[0];

            dispatch({
              type: DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS,
              payload: {
                records: allRecords,
                isLastPage: allRecords?.length < riskScenarioPageSize,
              },
            });
          })
          .catch((err) => {
            dispatch({
              type: DIAGRAM_FETCH_SCENARIO_RECORD_FAIL,
              payload: err.message,
            });
          });

        const bowtieData = await bowtieService.mapBowtieData(record, bowtieConfiguration);
        const data = {
          ...bowtieDataState,
          ...bowtieData,
        };

        dispatch({
          type: DIAGRAM_FETCH_RECORD_SUCCESS,
          payload: data,
        });
        addHazard?.current?.focus();
      } catch (err) {
        dispatch({
          type: DIAGRAM_FETCH_RECORD_FAIL,
          payload: err.message,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DIAGRAM_FETCH_RECORD_FAIL,
      payload: err.message,
    });
  }
};

const doFetchAndTransformRecord =
  (recordId, bowtieConfiguration, disableGlobalLoading = false) =>
  async (dispatch, getState) => {
    try {
      const recordPayload = await recordService.fetchRecord(recordId);
      const bowtieDataState = getState().diagram.bowtieData;

      if (isErrorResponse(recordPayload)) {
        dispatch({
          type: DIAGRAM_FETCH_RECORD_FAIL,
          payload: recordPayload.payload.message,
        });
      } else {
        const record = recordPayload.payload.data.result.results[0];

        if (!disableGlobalLoading) {
          dispatch({
            type: DIAGRAM_FETCH_RECORD_START,
            payload: 'Fetching Bowtie Configuration',
          });
        }

        dispatch({
          type: DIAGRAM_FETCH_SCENARIO_RECORD_START,
          payload: 'Fetching Scenario Records',
        });

        try {
          recordService
            .filterScenarioRecords(record, bowtieConfiguration, false)
            .then((records) => {
              dispatch({
                type: DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS,
                payload: {
                  records,
                  isLastPage: records?.length < riskScenarioPageSize,
                },
              });
            })
            .catch((err) => {
              dispatch({
                type: DIAGRAM_FETCH_SCENARIO_RECORD_FAIL,
                payload: err.message,
              });
            });

          const bowtieData = await bowtieService.mapBowtieData(record, bowtieConfiguration);

          const data = {
            ...bowtieDataState,
            ...bowtieData,
          };

          dispatch({
            type: DIAGRAM_FETCH_RECORD_SUCCESS,
            payload: data,
          });
        } catch (err) {
          dispatch({
            type: DIAGRAM_FETCH_RECORD_FAIL,
            payload: err.message,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: DIAGRAM_FETCH_RECORD_FAIL,
        payload: err.message,
      });
    }
  };

const doAddCauseInput = () => async (dispatch) => {
  const causePayload = {
    id: uuid4(),
    value: null,
    linkUrl: null,
    published: false,
    preventativeControls: [],
  };

  dispatch({ type: DIAGRAM_ADD_CAUSE_INPUT, payload: causePayload });
};

const doAddConsequenceInput = () => async (dispatch) => {
  const consequencePayload = {
    id: uuid4(),
    value: null,
    linkUrl: null,
    published: false,
    mitigatingControls: [],
  };

  dispatch({
    type: DIAGRAM_ADD_CONSEQUENCE_INPUT,
    payload: consequencePayload,
  });
};

const doAddMitigatingControlInput = (payload) => async (dispatch) => {
  const mitigatingPayload = {
    id: payload.placeholderId || uuid4(),
    value: payload.value,
    linkUrl: null,
    published: false,
    consequenceId: payload.index,
  };

  dispatch({
    type: DIAGRAM_ADD_MITIGATING_CONTROLS_INPUT,
    payload: mitigatingPayload,
  });
  dispatch(
    doUpdateDiagramLayout({
      isWidth: true,
    })
  );
};

const doAddPreventativeControlInput = (payload) => async (dispatch) => {
  const preventativePayload = {
    id: payload.placeholderId || uuid4(),
    value: payload.value,
    linkUrl: null,
    published: false,
    causeId: payload.index,
  };

  dispatch({
    type: DIAGRAM_ADD_PREVENTATIVE_CONTROLS_INPUT,
    payload: preventativePayload,
  });
  dispatch(
    doUpdateDiagramLayout({
      isWidth: true,
    })
  );
};

const doAddCause = (cause) => async (dispatch, getState) => {
  const state = getState();

  const {
    diagram: {
      bowtieData,
      bowtieData: { bowtieConfiguration },
    },
  } = state;

  try {
    const causePayload = {
      id: cause.id,
      value: cause.value,
      linkUrl: null,
      published: false,
      preventativeControls: [],
    };

    dispatch({ type: DIAGRAM_ADD_CAUSE_START });
    dispatch(
      doUpdateDiagramLayout({
        isWidth: true,
      })
    );

    const causeResponse = await recordService.addCauseConsequence(
      causePayload.value,
      bowtieConfiguration.forms.causes.id,
      bowtieConfiguration.forms.causes.moduleId,
      bowtieConfiguration.preventativeControls.causes.captionField,
      bowtieConfiguration.forms.causes.form,
      bowtieData.scenarioRecord.id
    );

    if (isSuccessResponse(causeResponse)) {
      causePayload.published = true;
      const fetchedCause = await recordService.fetchRecord(causeResponse.payload.data.result[0]?.id);
      if (isSuccessResponse(fetchedCause)) {
        const result = fetchedCause.payload.data.result.results[0];
        if (result) {
          causePayload.uuid = result.id;
          causePayload.linkUrl = result.linkUrl;
          causePayload.status = result.status;
        }
      }

      const standaloneCauses = localStorage.getItem('standaloneCauses');
      if (!standaloneCauses) {
        localStorage.setItem('standaloneCauses' + '_' + window.location.search, JSON.stringify([causePayload]));
      } else {
        const parsedStandaloneCauses = JSON.parse(standaloneCauses);
        localStorage.setItem(
          'standaloneCauses' + '_' + window.location.search,
          JSON.stringify([...parsedStandaloneCauses, causePayload])
        );
      }
      dispatch({ type: DIAGRAM_ADD_CAUSE_SUCCESS, payload: causePayload });
    } else {
      dispatch({
        type: DIAGRAM_ADD_CAUSE_FAIL,
        payload: 'Cause was not added. Please try again.',
      });
    }
  } catch (err) {
    dispatch({ type: DIAGRAM_ADD_CAUSE_FAIL, payload: err.message });
  }
};

const addCauseToAIDiagram = (cause) => async (dispatch) => {
  const causePayload = {
    id: cause.id,
    uuid: cause.id,
    value: cause.value,
    linkUrl: null,
    published: false,
    preventativeControls: [],
  };

  dispatch({ type: DIAGRAM_ADD_CAUSE_SUCCESS, payload: causePayload });
};

const updateCauseInDiagram = (cause) => async (dispatch) => {
  dispatch({ type: DIAGRAM_UPDATE_CAUSE, payload: cause });
};

const doRemoveCause = (id) => async (dispatch) => {
  dispatch({ type: DIAGRAM_REMOVE_CAUSE, payload: id });
};

const doAddConsequence = (consequence) => async (dispatch, getState) => {
  const state = getState();

  const {
    diagram: {
      bowtieData,
      bowtieData: { bowtieConfiguration },
    },
  } = state;

  try {
    const consequencePayload = {
      id: consequence.id,
      value: consequence.value,
      linkUrl: null,
      published: false,
      mitigatingControls: [],
    };

    dispatch({
      type: DIAGRAM_ADD_CONSEQUENCE_START,
      payload: consequencePayload,
    });
    dispatch(
      doUpdateDiagramLayout({
        isWidth: true,
      })
    );

    const consequenceResponse = await recordService.addCauseConsequence(
      consequencePayload.value,
      bowtieConfiguration.forms.consequences.id,
      bowtieConfiguration.forms.consequences.moduleId,
      bowtieConfiguration.mitigatingControls.consequences.captionField,
      bowtieConfiguration.forms.consequences.form,
      bowtieData.scenarioRecord.id
    );

    if (isSuccessResponse(consequenceResponse)) {
      consequenceResponse.published = true;
      const fetchedConsequence = await recordService.fetchRecord(consequenceResponse.payload.data.result[0]?.id);
      if (isSuccessResponse(fetchedConsequence)) {
        const result = fetchedConsequence.payload.data.result.results[0];
        if (result) {
          consequencePayload.uuid = result.id;
          consequencePayload.linkUrl = result.linkUrl;
          consequencePayload.status = result.status;
        }
      }

      dispatch({
        type: DIAGRAM_ADD_CONSEQUENCE_SUCCESS,
        payload: consequencePayload,
      });

      const standaloneConsequences = localStorage.getItem('standaloneConsequences' + '_' + window.location.search);
      if (!standaloneConsequences) {
        localStorage.setItem(
          'standaloneConsequences' + '_' + window.location.search,
          JSON.stringify([consequencePayload])
        );
      } else {
        const parsedStandaloneConsequences = JSON.parse(standaloneConsequences);
        localStorage.setItem(
          'standaloneConsequences' + '_' + window.location.search,
          JSON.stringify([...parsedStandaloneConsequences, consequencePayload])
        );
      }
    } else {
      dispatch({
        type: DIAGRAM_ADD_CAUSE_FAIL,
        payload: 'Consequence was not added. Please try again.',
      });
    }
  } catch (err) {
    dispatch({ type: DIAGRAM_ADD_CONSEQUENCE_FAIL, payload: err.message });
  }
};

const addConsequenceToAIDiagram = (consequence) => async (dispatch) => {
  const consequencePayload = {
    id: consequence.id,
    uuid: consequence.id,
    value: consequence.value,
    linkUrl: null,
    published: false,
    mitigatingControls: [],
  };

  dispatch({
    type: DIAGRAM_ADD_CONSEQUENCE_SUCCESS,
    payload: consequencePayload,
  });
};

const updateConsequenceInDiagram = (consequence) => async (dispatch) => {
  dispatch({ type: DIAGRAM_UPDATE_CONSEQUENCE, payload: consequence });
};

const doRemoveConsequence = (id) => async (dispatch) => {
  dispatch({ type: DIAGRAM_REMOVE_CONSEQUENCE, payload: id });
};

const doAddPreventativeControls = (preventativeControls) => async (dispatch, getState) => {
  const state = getState();
  const type = 'preventative_control';

  const {
    diagram: {
      bowtieData,
      bowtieData: { bowtieConfiguration },
      bowtieData: {
        bowtieConfiguration: { mitigatingControls: mitigatingControlsSettings },
      },
    },
  } = state;

  const sourceId = v4();

  try {
    const pcPayload = {
      id: preventativeControls.id,
      value: preventativeControls.value,
      linkUrl: null,
      published: false,
      parentId: preventativeControls.parentId,
    };

    dispatch({
      type: DIAGRAM_ADD_PREVENTATIVE_CONTROLS_START,
      payload: pcPayload,
    });

    const preventativeControlPayload = !preventativeControls.existingElement
      ? await recordService.addControl(
          sourceId,
          preventativeControls.value,
          bowtieConfiguration.forms.controls.id,
          bowtieConfiguration.forms.controls.moduleId,
          preventativeControls.parentId,
          bowtieConfiguration.preventativeControls.captionField,
          bowtieConfiguration.forms.controls.form,
          true
        )
      : await recordService.updateControl(
          preventativeControls.existingElement.id,
          preventativeControls.value,
          bowtieConfiguration.forms.controls.id,
          bowtieConfiguration.forms.controls.moduleId,
          preventativeControls.parentId,
          bowtieConfiguration.preventativeControls.captionField,
          preventativeControls.existingElement,
          true
        );

    let fields;

    if (!!bowtieData?.scenarioRecord?.fields) {
      fields = bowtieData?.scenarioRecord?.fields;
    } else {
      const record = await recordService.fetchRecord(bowtieData?.scenarioRecord?.id);
      fields = isSuccessResponse(record) ? record.payload.data.result.results[0].fields : {};
    }

    if (isSuccessResponse(preventativeControlPayload)) {
      await diagramService.updateRiskScenarioRecord(bowtieData.scenarioRecord.id, {
        formId: bowtieData.scenarioRecord.formId,
        formName: bowtieData.scenarioRecord.formName,
        status: bowtieData.scenarioRecord.status,
        preventative: true,
        fields,
        linkedRecordId: preventativeControls.existingElement
          ? preventativeControls.existingElement.id
          : preventativeControlPayload.payload.data.result[0]?.id,
      });

      const fetchedPreventativeControl = await recordService.fetchRecord(
        preventativeControls.existingElement
          ? preventativeControls.existingElement.id
          : preventativeControlPayload.payload.data.result[0]?.id
      );

      const result = isSuccessResponse(fetchedPreventativeControl)
        ? fetchedPreventativeControl.payload.data.result.results[0]
        : {};

      pcPayload.published = true;
      pcPayload.id = Number(
        preventativeControls.existingElement
          ? preventativeControls.existingElement.id
          : preventativeControlPayload.payload.data.result[0]?.id
      );
      pcPayload.linkUrl = preventativeControls.existingElement
        ? preventativeControls.existingElement.linkUrl
        : result.linkUrl;
      pcPayload.status = preventativeControls.existingElement
        ? preventativeControls.existingElement.status
        : result.status;

      const criticalOrNonCriticalField = result.fields[mitigatingControlsSettings.criticalOrNonCriticalField];
      pcPayload.criticalOrNonCritical =
        criticalOrNonCriticalField && isObject(criticalOrNonCriticalField) && criticalOrNonCriticalField.value;

      const effectiveOrNotEffectiveField =
        result[mitigatingControlsSettings.effectiveOrNotEffectiveField] ||
        result.fields[mitigatingControlsSettings.effectiveOrNotEffectiveField];
      pcPayload.effectiveOrNotEffective =
        effectiveOrNotEffectiveField && isObject(effectiveOrNotEffectiveField)
          ? effectiveOrNotEffectiveField.value
          : { value: effectiveOrNotEffectiveField };

      const standaloneCauses = localStorage.getItem('standaloneCauses' + '_' + window.location.search);

      if (standaloneCauses) {
        const parsedStandaloneCauses = JSON.parse(standaloneCauses);

        localStorage.setItem(
          'standaloneCauses' + '_' + window.location.search,
          JSON.stringify(parsedStandaloneCauses.filter((cause) => cause.uuid !== preventativeControls.parentId))
        );
      }

      const updatedScenarioRecordPayload = await recordService.fetchRecord(bowtieData.scenarioRecord.id);

      dispatch({
        type: DIAGRAM_ADD_PREVENTATIVE_CONTROLS_SUCCESS,
        payload: {
          pcPayload,
          riskScenarioRecord:
            isSuccessResponse(updatedScenarioRecordPayload) &&
            updatedScenarioRecordPayload.payload.data.result.results[0],
          global: Boolean(preventativeControls.existingElement?.global),
        },
      });

      dispatch(doUpdateQueue());
    } else {
      dispatch({
        type: DIAGRAM_ADD_PREVENTATIVE_CONTROLS_FAIL,
        payload: 'Preventative Control was not added. Please try again later.',
      });
      dispatch(doUpdateQueue());
      dispatch(
        doAddInFailedQueue({
          payload: preventativeControls,
          type,
        })
      );
    }
  } catch (err) {
    dispatch({
      type: DIAGRAM_ADD_PREVENTATIVE_CONTROLS_FAIL,
      payload: err.message,
    });
    dispatch(doUpdateQueue());
    dispatch(
      doAddInFailedQueue({
        payload: preventativeControls,
        type,
      })
    );
  }
};

const addPreventativeControlsToAIDiagram = (preventativeControls) => async (dispatch) => {
  // simulates the add of a preventative control, only for the diagram
  dispatch({
    type: DIAGRAM_ADD_PREVENTATIVE_CONTROLS_SUCCESS,
    payload: {
      pcPayload: { ...preventativeControls },
      global: false,
    },
  });

  dispatch(doUpdateQueue());
};

const updatePreventativeControlInDiagram = (preventativeControl) => (dispatch) => {
  dispatch({
    type: DIAGRAM_UPDATE_PREVENTATIVE_CONTROL,
    payload: {
      ...preventativeControl,
    },
  });

  dispatch(doUpdateQueue());
};

const doRemovePreventativeControl = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_REMOVE_PREVENTATIVE_CONTROL,
    payload,
  });
  dispatch(doUpdateFailedQueue());
};

const doRemoveMitigatingControl = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_REMOVE_MITIGATING_CONTROL,
    payload,
  });
  dispatch(doUpdateFailedQueue());
};

const doAddMitigatingControls = (mitigatingControls) => async (dispatch, getState) => {
  const state = getState();
  const type = 'mitigating_control';

  const {
    diagram: {
      bowtieData,
      bowtieData: { bowtieConfiguration },
      bowtieData: {
        bowtieConfiguration: { mitigatingControls: mitigatingControlsSettings },
      },
    },
  } = state;

  const sourceId = v4();

  try {
    const mcPayload = {
      id: mitigatingControls.id,
      value: mitigatingControls.value,
      linkUrl: null,
      published: false,
      parentId: mitigatingControls.parentId,
    };

    dispatch({
      type: DIAGRAM_ADD_MITIGATING_CONTROLS_START,
      payload: mcPayload,
    });

    const mitigatingControlPayload = !mitigatingControls.existingElement
      ? await recordService.addControl(
          sourceId,
          mitigatingControls.value,
          bowtieConfiguration.forms.controls.id,
          bowtieConfiguration.forms.controls.moduleId,
          mitigatingControls.parentId,
          bowtieConfiguration.mitigatingControls.captionField,
          bowtieConfiguration.forms.controls.form,
          false
        )
      : await recordService.updateControl(
          mitigatingControls.existingElement.id,
          mitigatingControls.value,
          bowtieConfiguration.forms.controls.id,
          bowtieConfiguration.forms.controls.moduleId,
          mitigatingControls.parentId,
          bowtieConfiguration.mitigatingControls.captionField,
          mitigatingControls.existingElement,
          false
        );

    let fields;

    if (!!bowtieData?.scenarioRecord?.fields) {
      fields = bowtieData?.scenarioRecord?.fields;
    } else {
      const record = await recordService.fetchRecord(bowtieData?.scenarioRecord?.id);
      fields = isSuccessResponse(record) ? record.payload.data.result.results[0].fields : {};
    }

    if (isSuccessResponse(mitigatingControlPayload)) {
      await diagramService.updateRiskScenarioRecord(bowtieData.scenarioRecord.id, {
        formId: bowtieData.scenarioRecord.formId,
        formName: bowtieData.scenarioRecord.formName,
        status: bowtieData.scenarioRecord.status,
        mitigating: true,
        fields,
        linkedRecordId: mitigatingControls.existingElement
          ? mitigatingControls.existingElement.id
          : mitigatingControlPayload.payload.data.result[0]?.id,
      });

      const fetchedMitigatingControl = await recordService.fetchRecord(
        mitigatingControls.existingElement
          ? mitigatingControls.existingElement.id
          : mitigatingControlPayload.payload.data.result[0]?.id
      );

      const result = isSuccessResponse(fetchedMitigatingControl)
        ? fetchedMitigatingControl.payload.data.result.results[0]
        : {};

      mcPayload.published = true;
      mcPayload.id = Number(
        mitigatingControls.existingElement
          ? mitigatingControls.existingElement.id
          : mitigatingControlPayload.payload.data.result[0].id
      );
      mcPayload.linkUrl = mitigatingControls.existingElement
        ? mitigatingControls.existingElement.linkUrl
        : result.linkUrl;
      mcPayload.status = mitigatingControls.existingElement ? mitigatingControls.existingElement.status : result.status;

      const criticalOrNonCriticalField = result.fields[mitigatingControlsSettings.criticalOrNonCriticalField];
      mcPayload.criticalOrNonCritical =
        criticalOrNonCriticalField && isObject(criticalOrNonCriticalField) && criticalOrNonCriticalField.value;

      const effectiveOrNotEffectiveField =
        result[mitigatingControlsSettings.effectiveOrNotEffectiveField] ||
        result.fields[mitigatingControlsSettings.effectiveOrNotEffectiveField];
      mcPayload.effectiveOrNotEffective =
        effectiveOrNotEffectiveField && isObject(effectiveOrNotEffectiveField)
          ? effectiveOrNotEffectiveField.value
          : { value: effectiveOrNotEffectiveField };

      const standaloneConsequences = localStorage.getItem('standaloneConsequences' + '_' + window.location.search);

      if (standaloneConsequences) {
        const parsedStandaloneConsequences = JSON.parse(standaloneConsequences);

        localStorage.setItem(
          'standaloneConsequences' + '_' + window.location.search,
          JSON.stringify(
            parsedStandaloneConsequences.filter((consequence) => consequence.uuid !== mitigatingControls.parentId)
          )
        );
      }

      const updatedScenarioRecordPayload = await recordService.fetchRecord(bowtieData.scenarioRecord.id);

      dispatch({
        type: DIAGRAM_ADD_MITIGATING_CONTROLS_SUCCESS,
        payload: {
          mcPayload,
          riskScenarioRecord:
            isSuccessResponse(updatedScenarioRecordPayload) &&
            updatedScenarioRecordPayload.payload.data.result?.results[0],
          global: Boolean(mitigatingControls.existingElement?.global),
        },
      });
      dispatch(doUpdateQueue());
    } else {
      dispatch({
        type: DIAGRAM_ADD_MITIGATING_CONTROLS_FAIL,
        payload: 'Mitigating Control was not added. Please try again later.',
      });
      dispatch(doUpdateQueue());
      dispatch(
        doAddInFailedQueue({
          payload: mitigatingControls,
          type,
        })
      );
    }
  } catch (err) {
    dispatch({
      type: DIAGRAM_ADD_MITIGATING_CONTROLS_FAIL,
      payload: err.message,
    });
    dispatch(doUpdateQueue());
    dispatch(
      doAddInFailedQueue({
        payload: mitigatingControls,
        type,
      })
    );
  }
};

const addMitigatingControlsToAIDiagram = (mitigatingControls) => async (dispatch) => {
  // simulates the add of a mitigating control, only for the diagram
  dispatch({
    type: DIAGRAM_ADD_MITIGATING_CONTROLS_SUCCESS,
    payload: {
      mcPayload: { ...mitigatingControls },
      global: false,
    },
  });

  dispatch(doUpdateQueue());
};

const updateMitigatingControlsInDiagram = (mitigatingControl) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_UPDATE_MITIGATING_CONTROL,
    payload: {
      ...mitigatingControl,
    },
  });

  dispatch(doUpdateQueue());
};

const doUpdateRiskScenarioValue = (bowtieData, payload, shouldExecuteUpdate) => async (dispatch) => {
  try {
    dispatch({
      type: DIAGRAM_UPDATE_BOWTIE_SCENARIO,
      payload,
    });

    if(shouldExecuteUpdate){
      const response = await diagramService.updateRiskScenarioField(bowtieData, payload, dispatch);

      if(response.data.result){
        dispatch({
          type: DIAGRAM_UPDATE_BOWTIE_SCENARIO_SUCCESS,
          payload,
        });
      }else{
        dispatch({
          type: DIAGRAM_UPDATE_BOWTIE_SCENARIO_FAIL,
          payload: 'Risk Scenario was not updated. Please try again.',
        });
      }
    }else{
      dispatch({
        type: DIAGRAM_UPDATE_BOWTIE_SCENARIO_SUCCESS,
        payload,
      });
    }

  } catch (err) {
    dispatch({
      type: DIAGRAM_UPDATE_BOWTIE_SCENARIO_FAIL,
      payload: err.message,
    });
  }
  
};

const doAddRiskScenario = (params) => async (dispatch) => {
  const { payload, urlParsed, addHazard, bowtieConfiguration } = params;

  try {
    dispatch({
      type: DIAGRAM_ADD_RISK_SCENARIO_START,
      payload: payload,
    });

    const riskScenarioRecordPayload = await diagramService.addRiskScenario(
      bowtieConfiguration.forms.main.form,
      bowtieConfiguration.scenario.captionField,
      payload
    );

    if (riskScenarioRecordPayload.success) {
      const recordId = riskScenarioRecordPayload.payload.data.result[0].id;
      const riskScenarioSavedRecordPayload = await recordService.fetchRecord(recordId);

      // urlParsed
      window.history.pushState(
        `&records=${recordId}`,
        '',
        `?${urlParsed.length > 0 ? `${urlParsed}&` : ''}records=${recordId}`
      );

      dispatch({
        type: DIAGRAM_SET_BOWTIE_DATA_LOADING,
        payload: false,
      });
      dispatch(
        diagramActions.doAddScenarioAndTransformRecord({
          riskScenarioSavedRecordPayload,
          addHazard,
          bowtieConfiguration,
        })
      );
    } else {
      dispatch({
        type: DIAGRAM_ADD_RISK_SCENARIO_FAIL,
        payload: 'Risk Scenario was not added. Please try again.',
      });
    }
  } catch (err) {
    dispatch({
      type: DIAGRAM_ADD_RISK_SCENARIO_FAIL,
      payload: err.message,
    });
  }
};

const doAddHazard = (params) => async (dispatch, getState) => {
  const { payload, onHazardDisabled, onEditHazard } = params;
  const state = getState();

  const {
    diagram: {
      bowtieData,
      bowtieData: { bowtieConfiguration },
    },
  } = state;

  try {
    dispatch({
      type: DIAGRAM_ADD_HAZARD_START,
      payload: payload,
    });

    const addHazardPayload = await diagramService.updateRiskScenarioHazard(
      bowtieData.scenarioRecord,
      bowtieConfiguration.hazard.captionField,
      payload,
      dispatch
    );

    if (addHazardPayload.result) {
      dispatch({
        type: DIAGRAM_ADD_HAZARD_SUCCESS,
        payload: payload,
      });
    } else {
      dispatch({
        type: DIAGRAM_ADD_HAZARD_FAIL,
        payload: 'Hazard was not added. Please try again.',
      });
    }

    onHazardDisabled(false);
    onEditHazard(false);
  } catch (err) {
    dispatch({
      type: DIAGRAM_ADD_HAZARD_FAIL,
      payload: err.message,
    });
  }
};

const doAddInQueue = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_QUEUE_ADD,
    payload,
  });
  dispatch(
    doUpdateDiagramLayout({
      isWidth: true,
    })
  );
};

const doAddInFailedQueue = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_QUEUE_ADD_FAILED,
    payload,
  });
};

const doUpdateQueueStatus = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_QUEUE_STATUS,
    payload,
  });
};

const doUpdateFailedQueueStatus = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_QUEUE_FAIL,
    payload,
  });
};

const doUpdateQueue = () => async (dispatch, getState) => {
  const newState = getState();
  const tasks = [...newState?.diagram?.queue?.tasks];
  tasks.splice(0, 1);

  dispatch({
    type: DIAGRAM_QUEUE_UPDATE,
    payload: tasks,
  });
  dispatch(
    doUpdateDiagramLayout({
      isWidth: true,
    })
  );
};

const doUpdateFailedQueue = () => async (dispatch, getState) => {
  const newState = getState();
  const failed = [...newState?.diagram?.queue?.failed];
  failed.splice(0, 1);

  dispatch({
    type: DIAGRAM_QUEUE_UPDATE_FAILED,
    payload: failed,
  });
};

const doUpdateDiagramLayout = (payload) => (dispatch) => {
  dispatch({
    type: DIAGRAM_LAYOUT,
    payload,
  });
};

const doSetBowtieDataLoading = (payload) => (dispatch) => {
  dispatch({
    type: DIAGRAM_SET_BOWTIE_DATA_LOADING,
    payload,
  });
};

const diagramActions = {
  doFetchAndTransformRecord,
  doAddCause,
  addCauseToAIDiagram,
  updateCauseInDiagram,
  doAddCauseInput,
  doAddConsequence,
  addConsequenceToAIDiagram,
  updateConsequenceInDiagram,
  doAddConsequenceInput,
  doAddPreventativeControlInput,
  doAddMitigatingControlInput,
  doAddPreventativeControls,
  addPreventativeControlsToAIDiagram,
  updatePreventativeControlInDiagram,
  doAddMitigatingControls,
  addMitigatingControlsToAIDiagram,
  updateMitigatingControlsInDiagram,
  doAddRiskScenario,
  doAddHazard,
  doRemoveCause,
  doRemoveConsequence,
  doRemoveMitigatingControl,
  doRemovePreventativeControl,
  doFetchRecordsByFormId,
  doResetFormRecords,
  doAddScenarioAndTransformRecord,
  doUpdateRiskScenarioValue,
  doAddInQueue,
  doUpdateQueueStatus,
  doUpdateQueue,
  doUpdateFailedQueueStatus,
  doAddInFailedQueue,
  doUpdateFailedQueue,
  doUpdateDiagramLayout,
  doSetBowtieDataLoading
};

export default diagramActions;
