import { Button, DropDown, DropDownNoSelection, DropDownResult, JsonData, OdinIcon } from '@myosh/odin-components';
import React, { forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useBowtieHazardMueMaterialUnwantedEventsPostMutation } from '../../api/generated/ai-api';
import { cn } from '../../helpers/util';
import Dialog, { DialogRef } from '../dialog';

interface BowtieWizardProps {
  onGenerateBowtie: (term: string, hazard: string, materialUnwantedEvent: string) => void;
}

export interface BowtieWizardRef {
  open: () => void;
  close: () => void;
}

interface WizardDropDownItem {
  text: string;
  value: string;
  mue: Array<DropDownResult>;
}

interface BowtieWizardState {
  canGenerateMUEs: boolean;
  loading: boolean;
  hazardMuePair: Array<WizardDropDownItem>;
  selectedMUE?: DropDownResult;
  selectedHazard?: DropDownResult;
}

const initialState: BowtieWizardState = {
  canGenerateMUEs: false,
  loading: false,
  hazardMuePair: [],
  selectedMUE: undefined,
  selectedHazard: undefined,
};

const BowtieWizard = ({ onGenerateBowtie }: BowtieWizardProps, ref: Ref<BowtieWizardRef>) => {
  const [state, setState] = useState<BowtieWizardState>(initialState);
  const { canGenerateMUEs, loading, hazardMuePair, selectedMUE, selectedHazard } = state;

  const dialogRef = useRef<DialogRef>(null);
  const termInputRef = useRef<HTMLInputElement>(null);

  const [getMaterialUnwantedEvents] = useBowtieHazardMueMaterialUnwantedEventsPostMutation();

  useImperativeHandle(ref, () => ({
    open: () => dialogRef.current?.open(),
    close,
  }));

  const close = useCallback(() => dialogRef.current?.close(), []);

  const handleDialogCancel = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
  }, []);

  const handleTermInputChange = () => {
    if (!termInputRef.current?.value) {
      setState((state) => ({ ...state, canGenerateMUEs: false, selectedMUE: undefined }));
    }
  };

  const handleGenerateMUEs = () => {
    if (termInputRef.current?.value && termInputRef.current.value.trim().length > 0) {
      setState((state) => ({
        ...state,
        canGenerateMUEs: true,
        loading: true,
        selectedMUE: undefined,
        selectedHazard: undefined,
      }));

      getMaterialUnwantedEvents({ hazardMueRequest: { term: termInputRef.current.value.trim() } })
        .unwrap()
        .then((data) => {
          const hazardMuePair = data.map(({ material_unwanted_event: materialUnwantedEvent, hazard }) => {
            return {
              text: hazard,
              value: hazard,
              mue: materialUnwantedEvent.map((mue) => {
                return {
                  text: mue,
                  value: mue,
                };
              }),
            };
          });

          setState((state) => ({ ...state, hazardMuePair }));
        })
        .catch(() => {
          toast.error('Unable to generate hazards and unwanted events, please try again later.');
        })
        .finally(() => {
          setState((state) => ({ ...state, loading: false }));
        });
    }
  };

  const handleMUESelectionChange = (
    materialUnwantedEvent?: DropDownResult | DropDownResult[] | DropDownNoSelection
  ) => {
    if (materialUnwantedEvent && !Array.isArray(materialUnwantedEvent)) {
      setState((state) => ({ ...state, selectedMUE: materialUnwantedEvent as DropDownResult }));
    }
  };

  const handleHazardSelectionChange = (hazard?: DropDownResult | DropDownResult[] | DropDownNoSelection) => {
    if (hazard && !Array.isArray(hazard)) {
      setState((state) => ({ ...state, selectedMUE: undefined, selectedHazard: hazard as DropDownResult }));
    }
  };

  const handleGenerateBowtieClick = () => {
    if (
      termInputRef.current?.value &&
      termInputRef.current.value.trim().length > 0 &&
      selectedMUE?.value &&
      selectedHazard?.value
    ) {
      onGenerateBowtie(termInputRef.current.value.trim(), selectedHazard.value as string, selectedMUE.value as string);
    }
  };

  const sectionStyles = 'bt-flex bt-flex-1 bt-flex-col bt-items-center bt-gap-4 bt-rounded-md bt-bg-gray-5 bt-p-4';

  const HazardDropDownSelectorStyles = cn('bt-text-sm bt-pl-2', {
    "before:bt-content-['Select_Hazard'] before:bt-text-gray-3": !selectedHazard,
  });

  const MUEDropDownSelectorStyles = cn('bt-text-sm bt-pl-2', {
    "before:bt-content-['Select_Unwanted_Event'] before:bt-text-gray-3": !selectedMUE,
  });

  return (
    <Dialog
      ref={dialogRef}
      onCancel={handleDialogCancel}
      className="bt-m-auto bt-w-[600px] bt-rounded-md bt-p-4 bt-text-gray-1 bt-outline-none"
    >
      <div className="bt-pb-4 bt-pt-0 bt-text-xl bt-font-medium">Bowtie AI Wizard</div>
      <div
        title="Close"
        className="bt-absolute bt-right-2 bt-top-2 bt-cursor-pointer bt-text-primary-2"
        onClick={close}
      >
        <OdinIcon icon="Close" />
      </div>
      <div className="bt-flex bt-flex-col bt-gap-8">
        <div className={sectionStyles}>
          <div className="bt-w-full">
            <label htmlFor="term-input" className="bt-flex bt-self-start bt-text-sm bt-font-bold">
              Describe an area or term to generate Unwanted Events for
            </label>
            <input
              ref={termInputRef}
              name="term-input"
              onChange={handleTermInputChange}
              className="bt-flex bt-w-full bt-flex-1 bt-appearance-none bt-border bt-border-gray-4 bt-px-2 bt-py-1 bt-text-sm focus:bt-outline-none"
            />
          </div>
          <Button
            variant="alternative"
            onClick={handleGenerateMUEs}
            disabled={canGenerateMUEs && loading}
            style={{ minWidth: '250px' }}
          >
            {canGenerateMUEs && loading ? 'Generating...' : 'Generate Hazards and Events'}
          </Button>
        </div>
        <div className={sectionStyles}>
          <DropDown
            className={cn({
              'bt-pointer-events-none bt-opacity-50': !canGenerateMUEs || loading,
            })}
            label="Hazards"
            data={hazardMuePair as unknown as JsonData}
            textField="text"
            valueField="value"
            allowClear={false}
            allowSearch={true}
            onChange={handleHazardSelectionChange}
            value={selectedHazard}
            selectorStyles={HazardDropDownSelectorStyles}
          />
          <DropDown
            className={cn({
              'bt-pointer-events-none bt-opacity-50': !canGenerateMUEs || loading || !selectedHazard?.value,
            })}
            label="Unwanted Events"
            data={selectedHazard?.originalData?.mue as unknown as JsonData}
            textField="text"
            valueField="value"
            allowClear={false}
            allowSearch={true}
            onChange={handleMUESelectionChange}
            value={selectedMUE}
            selectorStyles={MUEDropDownSelectorStyles}
          />
          <Button
            variant="alternative"
            type="primary"
            onClick={handleGenerateBowtieClick}
            disabled={!selectedMUE || !selectedHazard}
            style={{ maxWidth: '200px' }}
          >
            Generate Bowtie
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default forwardRef(BowtieWizard);
