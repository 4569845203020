import { createApi } from '@reduxjs/toolkit/query/react';
import { DynamicFormSettings, DynamicFormSettingsWrapper } from '../../@types/form-data-types';
import { baseQueryWithReAuth } from './base-api';

export const v4Api = createApi({
  reducerPath: 'v4Api',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    formConfiguration: builder.query<DynamicFormSettings, number>({
      //NOTE: failure on generating this specific endpoint, using this one for now
      query: (formId) => ({
        url: `/v4/forms/simple/${formId}`,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      transformResponse: (response: DynamicFormSettingsWrapper) => response.result,
    }),
  }),
});

export const { useFormConfigurationQuery } = v4Api;
