import { createReducer } from '@reduxjs/toolkit';
import {
  DIAGRAM_ADD_CAUSE_FAIL,
  DIAGRAM_ADD_CAUSE_INPUT,
  DIAGRAM_ADD_CAUSE_START,
  DIAGRAM_ADD_CAUSE_SUCCESS,
  DIAGRAM_ADD_CONSEQUENCE_FAIL,
  DIAGRAM_ADD_CONSEQUENCE_INPUT,
  DIAGRAM_ADD_CONSEQUENCE_START,
  DIAGRAM_ADD_CONSEQUENCE_SUCCESS,
  DIAGRAM_ADD_HAZARD_FAIL,
  DIAGRAM_ADD_HAZARD_START,
  DIAGRAM_ADD_HAZARD_SUCCESS,
  DIAGRAM_ADD_MITIGATING_CONTROLS_FAIL,
  DIAGRAM_ADD_MITIGATING_CONTROLS_INPUT,
  DIAGRAM_ADD_MITIGATING_CONTROLS_START,
  DIAGRAM_ADD_MITIGATING_CONTROLS_SUCCESS,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_FAIL,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_INPUT,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_START,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_SUCCESS,
  DIAGRAM_ADD_RISK_SCENARIO_FAIL,
  DIAGRAM_ADD_RISK_SCENARIO_START,
  DIAGRAM_ADD_RISK_SCENARIO_SUCCESS,
  DIAGRAM_CONFIGURATION_MAIN_FAIL,
  DIAGRAM_CONFIGURATION_MAIN_SUCCESS,
  DIAGRAM_FETCH_RECORD_FAIL,
  DIAGRAM_FETCH_RECORD_START,
  DIAGRAM_FETCH_RECORD_SUCCESS,
  DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL,
  DIAGRAM_FETCH_RECORDS_BY_FORM_START,
  DIAGRAM_FETCH_RECORDS_BY_FORM_SUCCESS,
  DIAGRAM_FETCH_SCENARIO_RECORD_FAIL,
  DIAGRAM_FETCH_SCENARIO_RECORD_MORE,
  DIAGRAM_FETCH_SCENARIO_RECORD_START,
  DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS,
  DIAGRAM_LAYOUT,
  DIAGRAM_QUEUE_ADD,
  DIAGRAM_QUEUE_ADD_FAILED,
  DIAGRAM_QUEUE_FAIL,
  DIAGRAM_QUEUE_STATUS,
  DIAGRAM_QUEUE_UPDATE,
  DIAGRAM_QUEUE_UPDATE_FAILED,
  DIAGRAM_REMOVE_CAUSE,
  DIAGRAM_REMOVE_CONSEQUENCE,
  DIAGRAM_REMOVE_MITIGATING_CONTROL,
  DIAGRAM_REMOVE_PREVENTATIVE_CONTROL,
  DIAGRAM_RESET_FORM_RECORDS,
  DIAGRAM_SET_BOWTIE_DATA_LOADING,
  DIAGRAM_UNLINK_CONTROL_FAIL,
  DIAGRAM_UNLINK_CONTROL_START,
  DIAGRAM_UNLINK_CONTROL_SUCCESS,
  DIAGRAM_UPDATE_BOWTIE_SCENARIO,
  DIAGRAM_UPDATE_BOWTIE_SCENARIO_FAIL,
  DIAGRAM_UPDATE_BOWTIE_SCENARIO_SUCCESS,
  DIAGRAM_UPDATE_CAUSE,
  DIAGRAM_UPDATE_CONSEQUENCE,
  DIAGRAM_UPDATE_MITIGATING_CONTROL,
  DIAGRAM_UPDATE_PREVENTATIVE_CONTROL
} from './diagramTypes';

const INITIAL_STATE = {
  errorMessage: null,
  loading: true,
  loadingMessage: null,
  bowtieData: {
    hazard: null,
    scenario: null,
    causes: [],
    consequences: [],
  },
  addBowtieData: {
    loading: false,
    errorMessage: null,
  },
  removeBowtieData: {
    loading: false,
    errorMessage: null,
  },
  formRecords: {
    loading: false,
    errorMessage: null,
    items: [],
  },
  riskScenarioRecords: {
    records: [],
    loading: true,
    errorMessage: null,
    isFetching: false,
    isLastPage: false,
  },
  configuration: {
    main: null,
  },
  queue: {
    tasks: [],
    failed: [],
    isQueuing: false,
    isRemovingQueue: false,
  },
  diagramLayout: {
    isWidth: false,
    isHeight: false,
  },
};

const diagramReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(DIAGRAM_FETCH_RECORD_START, (state, { payload }) => {
      state.loading = true;
      state.loadingMessage = payload;
      state.errorMessage = null;
    })

    .addCase(DIAGRAM_FETCH_RECORD_SUCCESS, (state, { payload }) => {
      state.bowtieData = { ...payload };
      state.loading = false;
      state.loadingMessage = null;
      state.errorMessage = null;
    })

    .addCase(DIAGRAM_FETCH_RECORD_FAIL, (state, { payload }) => {
      state.bowtieData = null;
      state.loading = false;
      state.loadingMessage = null;
      state.errorMessage = payload;
    })

    .addCase(DIAGRAM_ADD_CAUSE_INPUT, (state, { payload }) => {
      const placeholderInput = state.bowtieData?.causes?.find((cause) => !cause.value);

      if (placeholderInput) {
        return;
      }

      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        causes: [...(state.bowtieData.causes || []), payload],
      };
    })

    .addCase(DIAGRAM_ADD_CAUSE_START, (state) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: true,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_ADD_CAUSE_FAIL, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: payload,
      };
    })

    .addCase(DIAGRAM_ADD_CAUSE_SUCCESS, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        causes: [...state.bowtieData.causes.filter((cause) => cause.id !== payload.id), payload],
      };
    })

    .addCase(DIAGRAM_UPDATE_CAUSE, (state, { payload }) => {
      state.bowtieData = {
        ...state.bowtieData,
        causes: [
          ...state.bowtieData.causes.map((cause) => {
            if (cause.id === payload.id) {
              return { ...cause, value: payload.value };
            }
            return cause;
          }),
        ],
      };
    })

    .addCase(DIAGRAM_REMOVE_CAUSE, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        causes: [...state.bowtieData.causes.filter((cause) => cause.id !== payload)],
      };
    })

    .addCase(DIAGRAM_REMOVE_CONSEQUENCE, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        consequences: [...state.bowtieData.consequences.filter((consequence) => consequence.id !== payload)],
      };
    })

    .addCase(DIAGRAM_ADD_CONSEQUENCE_INPUT, (state, { payload }) => {
      const placeholderInput = state.bowtieData?.consequences?.find((consequence) => !consequence.value);

      if (placeholderInput) {
        return state;
      }

      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        consequences: [...(state.bowtieData.consequences || []), payload],
      };
    })

    .addCase(DIAGRAM_ADD_CONSEQUENCE_START, (state) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: true,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_ADD_CONSEQUENCE_FAIL, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: payload,
      };
    })

    .addCase(DIAGRAM_ADD_CONSEQUENCE_SUCCESS, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        consequences: [
          ...state.bowtieData.consequences.filter((consequence) => consequence.id !== payload.id),
          payload,
        ],
      };
    })

    .addCase(DIAGRAM_UPDATE_CONSEQUENCE, (state, { payload }) => {
      state.bowtieData = {
        ...state.bowtieData,
        consequences: [
          ...state.bowtieData.consequences.map((consequence) => {
            if (consequence.id === payload.id) {
              return { ...consequence, value: payload.value };
            }
            return consequence;
          }),
        ],
      };
    })

    .addCase(DIAGRAM_ADD_MITIGATING_CONTROLS_INPUT, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        consequences: [
          ...state.bowtieData.consequences.map((consequence, index) => {
            if (payload.consequenceId === index) {
              const placeholderInput = consequence.mitigatingControls.find(
                (mitigatingControl) => !mitigatingControl.value
              );
              if (placeholderInput) {
                return consequence;
              }

              return {
                ...consequence,
                mitigatingControls: [...consequence.mitigatingControls, payload],
              };
            }

            return consequence;
          }),
        ],
      };
    })

    .addCase(DIAGRAM_ADD_MITIGATING_CONTROLS_START, (state) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: true,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_ADD_MITIGATING_CONTROLS_FAIL, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: payload,
      };
    })

    .addCase(DIAGRAM_ADD_MITIGATING_CONTROLS_SUCCESS, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        scenarioRecord: payload.riskScenarioRecord,
        consequences: [
          ...state.bowtieData.consequences.map((consequence) => {
            if (consequence.uuid === payload.mcPayload.parentId) {
              return {
                ...consequence,
                mitigatingControls: [
                  ...consequence.mitigatingControls.filter((mitigatingControl) => mitigatingControl.value),
                  { ...payload.mcPayload, global: payload.global },
                ],
              };
            }
            return consequence;
          }),
        ],
      };
    })

    .addCase(DIAGRAM_UPDATE_MITIGATING_CONTROL, (state, { payload }) => {
      state.bowtieData = {
        ...state.bowtieData,
        consequences: [
          ...state.bowtieData.consequences.map((consequence) => {
            if (consequence.uuid === payload.parentId) {
              return {
                ...consequence,
                mitigatingControls: [
                  ...consequence.mitigatingControls.map((mitigatingControl) => {
                    if (mitigatingControl.id === payload.id) {
                      return { ...mitigatingControl, value: payload.value };
                    }
                    return mitigatingControl;
                  }),
                ],
              };
            }
            return consequence;
          }),
        ],
      };
    })

    .addCase(DIAGRAM_REMOVE_PREVENTATIVE_CONTROL, (state, { payload }) => {
      state.bowtieData = {
        ...state.bowtieData,
        causes: [
          ...state.bowtieData.causes.map((cause) => {
            if (payload.parentId === cause.uuid) {
              return {
                ...cause,
                preventativeControls: cause.preventativeControls.filter(
                  (preventativeControl) => preventativeControl.id !== payload.id
                ),
              };
            }

            return cause;
          }),
        ],
      };
    })

    .addCase(DIAGRAM_REMOVE_MITIGATING_CONTROL, (state, { payload }) => {
      state.bowtieData = {
        ...state.bowtieData,
        consequences: [
          ...state.bowtieData.consequences.map((consequence) => {
            if (payload.parentId === consequence.uuid) {
              return {
                ...consequence,
                mitigatingControls: consequence.mitigatingControls.filter(
                  (mitigatingControl) => mitigatingControl.id !== payload.id
                ),
              };
            }

            return consequence;
          }),
        ],
      };
    })

    .addCase(DIAGRAM_ADD_RISK_SCENARIO_START, (state) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: true,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_ADD_RISK_SCENARIO_FAIL, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: payload,
      };
    })

    .addCase(DIAGRAM_ADD_RISK_SCENARIO_SUCCESS, (state, { payload }) => {
      const storedRecords = JSON.parse(localStorage.getItem('bowtieRecords'));

      const updatedFormRecords = storedRecords?.filter((item) => item.value);

      updatedFormRecords.unshift(payload.recordItem);

      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        ...payload,
        records: updatedFormRecords,
      };
    })

    .addCase(DIAGRAM_ADD_HAZARD_START, (state) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: true,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_ADD_HAZARD_FAIL, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: payload,
      };
    })

    .addCase(DIAGRAM_ADD_HAZARD_SUCCESS, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        hazard: payload,
      };
    })

    .addCase(DIAGRAM_ADD_PREVENTATIVE_CONTROLS_INPUT, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        causes: [
          ...state.bowtieData.causes.map((cause, index) => {
            if (payload.causeId === index) {
              const placeholderInput = cause.preventativeControls.find(
                (preventativeControl) => !preventativeControl.value
              );
              if (placeholderInput) {
                return cause;
              }

              return {
                ...cause,
                preventativeControls: [...cause.preventativeControls, payload],
              };
            }

            return cause;
          }),
        ],
      };
    })

    .addCase(DIAGRAM_ADD_PREVENTATIVE_CONTROLS_START, (state) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: true,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_ADD_PREVENTATIVE_CONTROLS_FAIL, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: payload,
      };
    })

    .addCase(DIAGRAM_ADD_PREVENTATIVE_CONTROLS_SUCCESS, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
      state.bowtieData = {
        ...state.bowtieData,
        scenarioRecord: payload.riskScenarioRecord,
        causes: [
          ...state.bowtieData.causes.map((cause) => {
            if (cause.uuid === payload.pcPayload?.parentId) {
              return {
                ...cause,
                preventativeControls: [
                  ...cause.preventativeControls.filter((preventativeControl) => preventativeControl.value),
                  { ...payload.pcPayload, global: payload.global },
                ],
              };
            }
            return cause;
          }),
        ],
      };
    })

    .addCase(DIAGRAM_UPDATE_PREVENTATIVE_CONTROL, (state, { payload }) => {
      state.bowtieData = {
        ...state.bowtieData,
        causes: [
          ...state.bowtieData.causes.map((cause) => {
            if (cause.uuid === payload.parentId) {
              return {
                ...cause,
                preventativeControls: [
                  ...cause.preventativeControls.map((preventativeControl) => {
                    if (preventativeControl.id === payload.id) {
                      return { ...preventativeControl, value: payload.value };
                    }
                    return preventativeControl;
                  }),
                ],
              };
            }
            return cause;
          }),
        ],
      };
    })

    .addCase(DIAGRAM_UNLINK_CONTROL_START, (state, { payload }) => {
      state.bowtieData = {
        ...state.bowtieData,
        ...payload,
      };
      state.removeBowtieData = {
        loading: true,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_UNLINK_CONTROL_SUCCESS, (state) => {
      state.removeBowtieData = {
        loading: false,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_UNLINK_CONTROL_FAIL, (state,  { payload }) => {
      state.removeBowtieData = {
        loading: false,
        errorMessage: payload,
      };
    })

    .addCase(DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL, (state, { payload }) => {
      state.formRecords = {
        ...state.formRecords,
        loading: false,
        errorMessage: payload,
      };
    })

    .addCase(DIAGRAM_FETCH_RECORDS_BY_FORM_START, (state) => {
      state.formRecords = {
        ...state.formRecords,
        loading: true,
        errorMessage: null,
        items: [],
      };
    })

    .addCase(DIAGRAM_FETCH_RECORDS_BY_FORM_SUCCESS, (state, { payload }) => {
      state.formRecords = {
        ...state.formRecords,
        loading: false,
        errorMessage: null,
        items: payload,
      };
    })

    .addCase(DIAGRAM_RESET_FORM_RECORDS, (state) => {
      state.formRecords = INITIAL_STATE.formRecords;
    })

    .addCase(DIAGRAM_FETCH_SCENARIO_RECORD_START, (state) => {
      state.riskScenarioRecords = {
        records: [],
        loading: true,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_FETCH_SCENARIO_RECORD_MORE, (state, { payload }) => {
      const { records, isFetching, isLastPage } = payload;

      state.riskScenarioRecords = {
        records,
        loading: false,
        isFetching,
        isLastPage,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS, (state, { payload }) => {
      const { records, isLastPage } = payload;

      state.riskScenarioRecords = {
        records,
        loading: false,
        errorMessage: null,
        isFetching: false,
        isLastPage,
      };
    })

    .addCase(DIAGRAM_UPDATE_BOWTIE_SCENARIO, (state, { payload }) => {
      
      state.bowtieData = {
        ...state.bowtieData,
        scenario: payload,
      };
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: true,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_UPDATE_BOWTIE_SCENARIO_FAIL, (state, { payload }) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: payload,
      };
    })
    .addCase(DIAGRAM_UPDATE_BOWTIE_SCENARIO_SUCCESS, (state) => {
      state.addBowtieData = {
        ...state.addBowtieData,
        loading: false,
        errorMessage: null,
      };
    })

    .addCase(DIAGRAM_FETCH_SCENARIO_RECORD_FAIL, (state, { payload }) => {
      state.loading = false;
      state.riskScenarioRecords = {
        records: [],
        loading: false,
        errorMessage: payload,
      };
    })

    .addCase(DIAGRAM_CONFIGURATION_MAIN_SUCCESS, (state, { payload }) => {
      state.configuration = {
        main: payload,
      };
      state.loading = false;
    })

    .addCase(DIAGRAM_CONFIGURATION_MAIN_FAIL, (state) => {
      state.configuration = null;
    })

    .addCase(DIAGRAM_QUEUE_ADD, (state, { payload }) => {
      state.queue = {
        ...state.queue,
        tasks: [...state.queue?.tasks, payload],
      };
    })

    .addCase(DIAGRAM_QUEUE_ADD_FAILED, (state, { payload }) => {
      state.queue = {
        ...state.queue,
        failed: [...state.queue?.failed, payload],
      };
    })

    .addCase(DIAGRAM_QUEUE_STATUS, (state, { payload }) => {
      state.queue = {
        ...state.queue,
        isQueuing: payload,
      };
    })

    .addCase(DIAGRAM_QUEUE_FAIL, (state, { payload }) => {
      state.queue = {
        ...state.queue,
        isRemovingQueue: payload,
      };
    })

    .addCase(DIAGRAM_QUEUE_UPDATE, (state, { payload }) => {
      state.queue = {
        ...state.queue,
        tasks: payload,
        isQueuing: false,
      };
    })

    .addCase(DIAGRAM_QUEUE_UPDATE_FAILED, (state, { payload }) => {
      state.queue = {
        ...state.queue,
        failed: payload,
        isRemovingQueue: false,
      };
    })

    .addCase(DIAGRAM_LAYOUT, (state, { payload }) => {
      const { isWidth } = payload;

      state.diagramLayout = {
        ...state.diagramLayout,
        isWidth,
      };
    }
  )
    .addCase(DIAGRAM_SET_BOWTIE_DATA_LOADING, (state, { payload }) => {

      state.addBowtieData = {
        ...state.addBowtieData,
        loading: payload,
      };
    })

    .addDefaultCase((state) => state);
});

export default diagramReducer;
