import React, { createContext, useContext } from 'react';

interface AIContextProps {
  isAIGenerated: boolean;
  children: React.ReactNode;
}

const AIContext = createContext<boolean | undefined>(undefined);

export const AIContextProvider: React.FC<AIContextProps> = ({ isAIGenerated, children }: AIContextProps) => {
  return <AIContext.Provider value={isAIGenerated}>{children}</AIContext.Provider>;
};

// context consumer hook
export const useAIContext = () => {
  const context = useContext(AIContext);

  if (context === undefined) {
    throw new Error('useAIContext must be used within ThemeProvider');
  }

  return context;
};
