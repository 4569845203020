import { aiApi as api } from '../base/ai-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    versionGet: build.query<VersionGetApiResponse, VersionGetApiArg>({
      query: () => ({ url: `/bowtie/version` }),
    }),
    bowtieHazardMueMaterialUnwantedEventsPost: build.mutation<
      BowtieHazardMueMaterialUnwantedEventsPostApiResponse,
      BowtieHazardMueMaterialUnwantedEventsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/bowtie/material-unwanted-events`,
        method: 'POST',
        body: queryArg.hazardMueRequest,
      }),
    }),
    bowtieFactorsPost: build.query<BowtieFactorsPostApiResponse, BowtieFactorsPostApiArg>({
      query: (queryArg) => ({ url: `/bowtie/factors`, method: 'POST', body: queryArg.factorRequest }),
    }),
    bowtieConvertPost: build.mutation<BowtieConvertPostApiResponse, BowtieConvertPostApiArg>({
      query: (queryArg) => ({ url: `/bowtie/convert`, method: 'POST', body: queryArg.imageBowtieRequest }),
    }),
    bowtieSuggestPost: build.mutation<BowtieSuggestPostApiResponse, BowtieSuggestPostApiArg>({
      query: (queryArg) => ({ url: `/bowtie/suggest`, method: 'POST', body: queryArg.bowtieCorrectionRequest }),
    }),
    bowtieConvertAnalysisPost: build.mutation<BowtieConvertAnalysisPostApiResponse, BowtieConvertAnalysisPostApiArg>({
      query: (queryArg) => ({
        url: `/bowtie/background-job/convert-analysis/start`,
        method: 'POST',
        body: queryArg.bowtieAnalysisConvertRequest,
      }),
    }),
    bowtieConvertAnalysisJobIdGet: build.query<
      BowtieConvertAnalysisJobIdGetApiResponse,
      BowtieConvertAnalysisJobIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/bowtie/background-job/convert-analysis/${queryArg.jobId}` }),
    }),
    widgetInsightsDataAnalysisPost: build.mutation<
      WidgetInsightsDataAnalysisPostApiResponse,
      WidgetInsightsDataAnalysisPostApiArg
    >({
      query: (queryArg) => ({ url: `/widget-insights/data-analysis`, method: 'POST', body: queryArg.widgetRequest }),
    }),
    rootGet: build.query<RootGetApiResponse, RootGetApiArg>({
      query: () => ({ url: `/` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as aiApi };
export type VersionGetApiResponse = /** status 200 Successful Response */ any;
export type VersionGetApiArg = void;
export type BowtieHazardMueMaterialUnwantedEventsPostApiResponse =
  /** status 200 Successful Response */ HazardMuePair[];
export type BowtieHazardMueMaterialUnwantedEventsPostApiArg = {
  hazardMueRequest: HazardMueRequest;
};
export type BowtieFactorsPostApiResponse = /** status 200 Successful Response */ Detail;
export type BowtieFactorsPostApiArg = {
  factorRequest: FactorRequest;
};
export type BowtieConvertPostApiResponse = /** status 200 Successful Response */ BowtieResponse;
export type BowtieConvertPostApiArg = {
  imageBowtieRequest: ImageBowtieRequest;
};
export type BowtieSuggestPostApiResponse = /** status 200 Successful Response */ SuggestResponse;
export type BowtieSuggestPostApiArg = {
  bowtieCorrectionRequest: BowtieCorrectionRequest;
};
export type BowtieConvertAnalysisPostApiResponse = /** status 200 Successful Response */ string;
export type BowtieConvertAnalysisPostApiArg = {
  bowtieAnalysisConvertRequest: BowtieAnalysisConvertRequest;
};
export type BowtieConvertAnalysisJobIdGetApiResponse =
  /** status 200 Successful Response */ BackgroundJobConvertResponse;
export type BowtieConvertAnalysisJobIdGetApiArg = {
  jobId: string;
};
export type WidgetInsightsDataAnalysisPostApiResponse = /** status 200 Successful Response */ WidgetResponse;
export type WidgetInsightsDataAnalysisPostApiArg = {
  widgetRequest: WidgetRequest;
};
export type RootGetApiResponse = /** status 200 Successful Response */ any;
export type RootGetApiArg = void;
export type HazardMuePair = {
  /** The operation, activity, or material that has one or more material unwanted event that could happen */
  hazard: string;
  /** List of material unwanted event associated with the hazard */
  material_unwanted_event: string[];
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type HazardMueRequest = {
  term: string;
};
export type FactorType = 'Cause' | 'Consequence';
export type ControlType = 'Preventative' | 'Mitigating';
export type ControlWithId = {
  /** Control short name */
  name: string;
  /** Control category (Hard or Soft) */
  category: 'Hard' | 'Soft';
  /** Control type (Preventative or Mitigating) */
  type: ControlType;
  /** Indicates whether this control is global */
  isGlobal: boolean;
  /** Indicates whether this control is critical */
  isCritical: boolean;
  /** Indicates whether this control is required to be initially implemented */
  isInitial: boolean;
  /** Control category type */
  controlType: string;
  /** Control ID */
  id: string;
  /** Indicates whether this control is a control issue */
  isControlIssues: boolean;
};
export type FactorId = {
  /** Factor ID */
  id: string;
  /** Factor name */
  name: string;
  /** Factor type (Cause or Consequence) */
  type: FactorType;
  /** List of controls related to a factor */
  controls: ControlWithId[];
  /** Factor position */
  position: number;
};
export type Detail = {
  /** The source of a material unwanted event, whether it be an operation, activity, or material. */
  hazard: string;
  /** List of causes of a material unwanted event */
  causes: FactorId[];
  /** List of consequences of a material unwanted event */
  consequences: FactorId[];
};
export type FactorRequest = {
  term: string;
  selected_MUE: string;
  cause_qty?: number | null;
  consequence_qty?: number | null;
  preventative_qty?: number | null;
  mitigation_qty?: number | null;
  control_types?: string[] | null;
  hazard?: string | null;
};
export type BowtieResponse = {
  /** The Term/Industry/Department to find the risks of */
  term: string;
  /** The Risk of the term to find the cause and consequence */
  risk: string;
  /** List of causes of a material unwanted event */
  causes: FactorId[];
  /** List of consequences of a material unwanted event */
  consequences: FactorId[];
};
export type ImageBowtieRequest = {
  control_types: string[];
  image: string;
};
export type SuggestResponse = {
  /** Suggested text version to correct the original Bowtie Diagram with what to add, remove, or modify */
  suggestion_text: string;
};
export type ControlRequestWithId = {
  /** Control short name */
  name: string;
  /** Control category (Hard or Soft) */
  category: 'Hard' | 'Soft';
  /** Control type, where preventive control is a measure or action that is designed to stop a risk or hazard from occurring in the first place and mitigating control (also referred to as a mitigation or a barrier) is a specific action, procedure, or mechanism that reduces the impact of an event after it has occurred. */
  type: 'Preventative' | 'Mitigating';
  /** Indicates whether this control is global */
  isGlobal: boolean;
  /** Indicates whether this control is critical */
  isCritical: boolean;
  /** Indicates whether this control is required to be initially implemented */
  isInitial: boolean;
  /** Control category type */
  controlType?: string | null;
  id: string;
};
export type CauseRequestWithId = {
  /** Cause short name of a material unwanted event */
  name: string;
  controls: ControlRequestWithId[];
  id: string;
};
export type ConsequenceRequestWithId = {
  /** Consequence short name of a material unwanted event */
  name: string;
  controls: ControlRequestWithId[];
  id: string;
};
export type BowtieRequestWithId = {
  term: string;
  risk: string;
  causes: CauseRequestWithId[];
  consequences: ConsequenceRequestWithId[];
  hazard: string;
};
export type BowtieCorrectionRequest = {
  bowtie: BowtieRequestWithId;
  control_types?: string[] | null;
};
export type BowtieAnalysisConvertRequest = {
  bowtie: BowtieRequestWithId;
  analysis: string;
  control_types?: string[] | null;
};
export type ConvertAnalysisResponse = {
  /** List of causes of a material unwanted event */
  causes: FactorId[];
  /** List of consequences of a material unwanted event */
  consequences: FactorId[];
};
export type BackgroundJobConvertResponse = {
  /** The state of the background job */
  state: 'PENDING' | 'STARTED' | 'RETRY' | 'FAILURE' | 'SUCCESS';
  /** The status information detail of the background job */
  status: string;
  /** The result of the background job consisting of the causes and consequences of a material unwanted event, if available */
  result: ConvertAnalysisResponse | null;
};
export type WidgetResponse = {
  /** Information fetched upon data analysis on widget data. */
  data_analysis: string;
  /** Description of what might have caused the data distribution noticed from data analysis */
  potential_causes: string;
  /** Recommendations on what to improve so that the charts shows improvements. */
  recommendations: string;
  /** Short summary of the data analysis, causes and recommendations. */
  summary: string;
};
export type WidgetType = 'CHART_COLUMN';
export type TimeFrequency = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY';
export type WidgetFilters = {
  id?: string;
  values?: string[];
};
export type WidgetDataProperties = {
  timeFrequency?: TimeFrequency | null;
  /** This is the first criteria by which the chart data are grouped by. It should always be provided. */
  field: string;
  /** This is the second criteria by which the chart data are grouped by. Not mandatory. When present the data are stacked. */
  secondField?: string | null;
  /** Indicates whether the records in the database are filtered by creation date or not. */
  ignoreDateFilters?: boolean | null;
  /** Indicates whether there are any additional filters applied when fetching records from the database and preparing the chart data. If present, specified the filtering criteria. */
  globalFilters?: WidgetFilters[] | null;
  /** Indicates whether there are any additional filters applied when fetching records from the database and preparing the chart data. If present, specified the filtering criteria. */
  customFilters?: WidgetFilters[] | null;
};
export type Widget = {
  /** Id of the widget must always be provided. */
  id: string;
  /** Title of the widget. Sometimes may be an indicator of what the data represent. must always be provided. */
  title: string;
  /** Represents how the data are shown and aggregated. Must always be provided. */
  type: WidgetType;
  /** Properties of the widget which specify how the chart data is prepared and what filtering and/or grouping criteria are applied. This property must always be provided. */
  widgetData: WidgetDataProperties;
};
export type WidgetAggregateData = {
  /** Field represents a category of the chart (generally xAxis value), no data value can be represented without an appropriate category. It should always be provided. */
  field: string;
  secondField?: string | null;
  value: string;
};
export type WidgetRequest = {
  /** Widget details and properties. Each widget represents a chart. */
  widget: Widget;
  /** Widget/chart data represented by field, second field if it is present and a value. */
  data: WidgetAggregateData[];
};
export const {
  useVersionGetQuery,
  useLazyVersionGetQuery,
  useBowtieHazardMueMaterialUnwantedEventsPostMutation,
  useBowtieFactorsPostQuery,
  useLazyBowtieFactorsPostQuery,
  useBowtieConvertPostMutation,
  useBowtieSuggestPostMutation,
  useBowtieConvertAnalysisPostMutation,
  useBowtieConvertAnalysisJobIdGetQuery,
  useLazyBowtieConvertAnalysisJobIdGetQuery,
  useWidgetInsightsDataAnalysisPostMutation,
  useRootGetQuery,
  useLazyRootGetQuery,
} = injectedRtkApi;
