import { cn } from '../helpers/util';

interface LoadingControlsProps {
  className?: string;
}

export default function LoadingControls({ className }: LoadingControlsProps) {
  return (
    <div
      className={cn(
        'bt-pointer-events-none bt-flex bt-h-0 bt-w-auto bt-items-end bt-justify-center bt-text-5xl',
        className
      )}
    >
      <span className="bt-animate-pulse">.</span>
      <span className="bt-animate-pulse-short">.</span>
      <span className="bt-animate-pulse-long">.</span>
    </div>
  );
}
