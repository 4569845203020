import { cn } from '../../../../helpers/util';
import diagramActions from '../../../../modules/diagram/diagramActions';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import userSelectors from '../../../../modules/user/userSelectors';
import { BowtieStateCauseData, BowtieStateConsequenceData } from '../../../../services/bowtie-data-types';
import { BowtieModuleForm } from '../../../../services/common-data-types';
import Button from '../../../add-button.component';
import { ContainerType } from '../../../controls.component';
import MultiElementLine, { MultiElementLineType } from './multi-element-line';
import SingleElementLine from './single-element-line';

const MULTI_LINE_CONTAINER_MODE = {
  SINGLE: 'single',
  MULTI: 'multi',
};

export const CONTAINER_ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
};

interface MultiLineContainerProps {
  id: string;
  mode: string;
  type: ContainerType;
  form: BowtieModuleForm;
  alignment: string;
  controlLines?: Array<BowtieStateCauseData> | Array<BowtieStateConsequenceData> | Array<MultiElementLineType>;
}

export default function MultiLineContainer({ id, mode, type, form, alignment, controlLines }: MultiLineContainerProps) {
  const dispatch = useAppDispatch();
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const buttonClicked = () => {
    if (type === ContainerType.CAUSES) {
      dispatch(diagramActions.doAddCauseInput());
    } else {
      dispatch(diagramActions.doAddConsequenceInput());
    }
  };

  const renderContainer = () => {
    if (mode === MULTI_LINE_CONTAINER_MODE.SINGLE) {
      return (
        controlLines &&
        controlLines.length > 0 &&
        controlLines.map((line, index) => {
          return (
            <SingleElementLine
              id={id}
              key={`${line.id}_${index}`}
              type={type}
              index={index}
              line={line as BowtieStateCauseData | BowtieStateConsequenceData}
            />
          );
        })
      );
    }

    if (mode === MULTI_LINE_CONTAINER_MODE.MULTI) {
      return (
        controlLines &&
        controlLines.length > 0 &&
        controlLines.map((line, index) => (
          <MultiElementLine
            id={id}
            type={type}
            key={`${line.id}_${index}`}
            alignment={alignment}
            multiLines={line as MultiElementLineType}
            index={index}
            form={form}
          />
        ))
      );
    }

    return null;
  };

  const canAddItems =
    mode === MULTI_LINE_CONTAINER_MODE.SINGLE && userWithPermissions?.permissions?.createRecord?.[`${id}`];

  return (
    <>
      <div
        className={cn('bt-mr-4 bt-flex bt-flex-col bt-justify-between', {
          '-bt-mt-12': mode === MULTI_LINE_CONTAINER_MODE.MULTI,
        })}
      >
        {renderContainer()}
      </div>
      {canAddItems && <Button type={type} text={`Add ${id}`} buttonClicked={buttonClicked} />}
    </>
  );
}
