import { Button } from '@myosh/odin-components';

interface ActionButton {
  onAction: React.MouseEventHandler;
  text: string;
}

interface ActionModalContentProps {
  actionButton: ActionButton;
  onClose: React.MouseEventHandler;
  text: string;
}

export default function ActionModalContent({ actionButton, onClose, text }: ActionModalContentProps) {
  return (
    <div className="bt-flex bt-flex-col">
      <div className="bt-px-0 bt-py-4">{text}</div>
      <div className="bt-flex bt-translate-y-[20%] bt-flex-row-reverse bt-gap-2 bt-px-0 bt-py-4">
        <Button type="text" onClick={onClose}>
          Cancel
        </Button>
        <Button type="primary" onClick={actionButton.onAction}>
          {actionButton.text}
        </Button>
      </div>
    </div>
  );
}
