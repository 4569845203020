import React, { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef } from 'react';

interface DialogProps {
  className: string;
  children: React.ReactNode | Array<React.ReactNode>;
  onCancel?: (event: React.SyntheticEvent) => void;
  onClose?: () => void;
}

export interface DialogRef {
  open: () => void;
  close: () => void;
}

const Dialog = ({ children, className, onCancel, onClose }: DialogProps, ref: Ref<DialogRef>) => {
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    modalRef.current?.addEventListener('keydown', escKeyHandler);

    return () => {
      modalRef.current?.removeEventListener('keydown', escKeyHandler);
      modalRef.current?.close();
    };
  }, []);

  const open = useCallback(() => {
    modalRef.current?.showModal();
  }, []);

  const close = useCallback(() => {
    modalRef.current?.close();
  }, []);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <dialog ref={modalRef} onCancel={onCancel} onClose={onClose} className={className}>
      {children}
    </dialog>
  );
};

export default forwardRef(Dialog);

// miscellaneous
const escKeyHandler = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    event.preventDefault();
  }
};
