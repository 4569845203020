import { useEffect, useRef, useState } from 'react';
import diagramActions from '../../../modules/diagram/diagramActions';
import { useAppDispatch, useAppSelector } from '../../../modules/hooks';
import userSelectors from '../../../modules/user/userSelectors';
import { BowtieStateCauseData, BowtieStateConsequenceData, DIAGRAM_MODE } from '../../../services/bowtie-data-types';
import BowtieDiagramLine, { DiagramLineDirection } from '../diagram-line/bowtie-diagram-line.component';
import ButterflyDiagramLine from '../diagram-line/butterfly-diagram-line.component';

interface LineSkeletonProps {
  causes?: Array<BowtieStateCauseData>;
  consequences?: Array<BowtieStateConsequenceData>;
  diagramMode: DIAGRAM_MODE;

  isHazardVisible: boolean;
}

export default function LineSkeleton({ causes, consequences, diagramMode, isHazardVisible }: LineSkeletonProps) {
  const [mode, setMode] = useState(diagramMode);

  const dispatch = useAppDispatch();
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const mainAreaContainerRef = useRef<HTMLElement | null>();
  const generalAreaCausesContainerRef = useRef<HTMLElement | null>();
  const generalAreaPreventativeControlContainerRef = useRef<HTMLElement | null>();
  const causesContainerRef = useRef<HTMLElement | null>();
  const preventativeControlContainerRef = useRef<HTMLElement | null>();
  const mitigatingControlContainerRef = useRef<HTMLElement | null>();
  const riskScenarioRef = useRef<HTMLElement | null>(); // the risk scenario container
  const hazardRef = useRef<HTMLElement | null>(); // the hazard container

  mainAreaContainerRef.current = document.getElementById('main-area-container');
  generalAreaCausesContainerRef.current = document.getElementById('ga-causes-container');
  generalAreaPreventativeControlContainerRef.current = document.getElementById('ga-preventative-controls-container');
  causesContainerRef.current = document.getElementById('causes-container');
  preventativeControlContainerRef.current = document.getElementById('preventative-controls-container');
  mitigatingControlContainerRef.current = document.getElementById('mitigating-controls-container');
  riskScenarioRef.current = document.getElementById('risk-scenario');
  hazardRef.current = isHazardVisible ? document.getElementById('hazard') : null;

  useEffect(() => {
    // This use effect should not be needed, however, as we do not know when the <Diagram/> has updated it's internals when the
    // diagramMode changes, we trigger an additional render from this useEffect to correct the line skeleton.
    if (mode !== diagramMode) {
      setMode(diagramMode);
    }
  }, [diagramMode]);

  const canDrawLineBetweenHazardAndRiskScenario =
    mainAreaContainerRef.current &&
    riskScenarioRef.current &&
    hazardRef.current &&
    generalAreaPreventativeControlContainerRef.current &&
    generalAreaCausesContainerRef.current;

  const hazardToRiskScenarioLineCoordinates = () => {
    if (canDrawLineBetweenHazardAndRiskScenario) {
      const x =
        hazardRef.current!.offsetTop +
        mainAreaContainerRef.current!.clientWidth / 2 +
        generalAreaPreventativeControlContainerRef.current!.clientWidth +
        generalAreaCausesContainerRef.current!.clientWidth;

      return {
        x1: x,
        y1: hazardRef.current!.offsetTop + hazardRef.current!.clientHeight,
        x2: x,
        y2: riskScenarioRef.current!.offsetTop,
      };
    }
  };

  return (
    <svg height="100%" width="100%" className="bt-absolute bt-left-0 bt-top-0 bt-z-[2000]">
      {canDrawLineBetweenHazardAndRiskScenario && (
        <line className="bt-stroke-cline bt-stroke-1" {...hazardToRiskScenarioLineCoordinates()} />
      )}

      {/* Butterfly configuration */}
      {diagramMode === DIAGRAM_MODE.BUTTERFLY &&
        preventativeControlContainerRef.current &&
        riskScenarioRef.current &&
        causes?.map((cause, index) => {
          const causeElement = document.getElementById('causes_' + (index + 1));
          return (
            causeElement && (
              <ButterflyDiagramLine
                key={`cause_${cause.id}_butterfly`}
                userHasPermissions={
                  userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls
                }
                startItem={causeElement}
                controlContainer={preventativeControlContainerRef.current!}
                endItem={riskScenarioRef.current!}
                onClick={() => dispatch(diagramActions.doAddPreventativeControlInput({ index, value: null }))}
              />
            )
          );
        })}

      {diagramMode === DIAGRAM_MODE.BUTTERFLY &&
        mitigatingControlContainerRef.current &&
        riskScenarioRef.current &&
        consequences?.map((consequence, index) => {
          const consequenceElement = document.getElementById('consequences_' + (index + 1));
          return (
            consequenceElement && (
              <ButterflyDiagramLine
                key={`consequence_${consequence.id}_butterfly`}
                direction={DiagramLineDirection.RightToLeft}
                userHasPermissions={
                  userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls
                }
                startItem={consequenceElement}
                controlContainer={mitigatingControlContainerRef.current!}
                endItem={riskScenarioRef.current!}
                onClick={() => dispatch(diagramActions.doAddMitigatingControlInput({ index, value: null }))}
              />
            )
          );
        })}

      {/* Bowtie configuration */}
      {diagramMode === DIAGRAM_MODE.BOWTIE &&
        preventativeControlContainerRef.current &&
        riskScenarioRef.current &&
        causes?.map((cause, index) => {
          const causeElement = document.getElementById('causes_' + (index + 1));
          return (
            causeElement && (
              <BowtieDiagramLine
                key={`cause_${cause.id}_bowtie`}
                userHasPermissions={
                  userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls
                }
                startItem={causeElement}
                controlContainer={preventativeControlContainerRef.current!}
                endItem={riskScenarioRef.current!}
                onClick={() => dispatch(diagramActions.doAddPreventativeControlInput({ index, value: null }))}
              />
            )
          );
        })}

      {diagramMode === DIAGRAM_MODE.BOWTIE &&
        mitigatingControlContainerRef.current &&
        riskScenarioRef.current &&
        consequences?.map((consequence, index) => {
          const consequenceElement = document.getElementById('consequences_' + (index + 1));

          return (
            consequenceElement && (
              <BowtieDiagramLine
                key={`consequence_${consequence.id}_bowtie`}
                direction={DiagramLineDirection.RightToLeft}
                userHasPermissions={
                  userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls
                }
                startItem={consequenceElement}
                controlContainer={mitigatingControlContainerRef.current!}
                endItem={riskScenarioRef.current!}
                onClick={() => dispatch(diagramActions.doAddMitigatingControlInput({ index, value: null }))}
              />
            )
          );
        })}
    </svg>
  );
}
