import {
  DefinitionsFromApi,
  OverrideResultType,
  TagTypesFromApi,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BowtieAddonConfig } from '../../@types/api.types';
import {
  AddonMappingDto,
  EntityResponseDtoAddonResultDto,
  GetAddonMappingsApiResponse,
  GetSimpleOptionsForFieldApiResponse,
  v4Api,
} from '../generated/v4-api';

type Definitions = DefinitionsFromApi<typeof v4Api>;
type TagTypes = TagTypesFromApi<typeof v4Api>;

type TransformedGetAddonMappingsResultType = AddonMappingDto;
type TransformedGetAddon1ResultType = BowtieAddonConfig;
type TransformedGetSimpleOptionsForFieldResultType = Array<string>;

type UpdatedDefinitions = Omit<Definitions, 'getAddonMappings' | 'getAddon1'> & {
  getAddonMappings: OverrideResultType<Definitions['getAddonMappings'], TransformedGetAddonMappingsResultType>;
  getAddon1: OverrideResultType<Definitions['getAddon1'], TransformedGetAddon1ResultType>;
  getSimpleOptionsForField: OverrideResultType<
    Definitions['getSimpleOptionsForField'],
    TransformedGetSimpleOptionsForFieldResultType
  >;
};

export const enhancedV4Api = v4Api.enhanceEndpoints<TagTypes, UpdatedDefinitions>({
  endpoints: {
    getAddonMappings: {
      query: () => ({
        url: `/v4/add-ons/mappings?filter=name:eq:bowtie`,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      transformResponse: (response: GetAddonMappingsApiResponse): TransformedGetAddonMappingsResultType =>
        response!.result![0],
    },
    getAddon1: {
      query: (queryArg) => ({
        url: `/v4/add-ons/${queryArg.id}`,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      transformResponse: ({
        result: { result } = {},
      }: EntityResponseDtoAddonResultDto): TransformedGetAddon1ResultType => {
        const bowtieConfiguration = JSON.parse(result?.jsonObject ?? '{}');
        return {
          ...result,
          bowtieConfiguration,
        };
      },
    },
    getSimpleOptionsForField: {
      transformResponse: (response: GetSimpleOptionsForFieldApiResponse) => {
        return response.items?.map((item) => item.caption!) ?? [];
      },
    },
  },
});

export const {
  useGetAddonMappingsQuery: useBowtieMappingQuery,
  useGetAddon1Query: useBowtieAddonQuery,
  useLazyGetSimpleOptionsForFieldQuery,
} = enhancedV4Api;
