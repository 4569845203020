import AddPlus from './add-plus.component';
import { DiagramLineDirection, DiagramLineProps, DiagramLineSettings } from './bowtie-diagram-line.component';

const selectorLineDeltaX = 2;
const connectorLineDeltaX = -0.375;

const ButterflyDiagramLine = ({
  userHasPermissions,
  startItem,
  controlContainer,
  endItem,
  direction = DiagramLineDirection.LeftToRight,
  onClick,
}: DiagramLineProps) => {
  const startItemParent = startItem.offsetParent as HTMLElement;
  const endItemParent = endItem.offsetParent as HTMLElement;

  const calculateSelectorLine = (): DiagramLineSettings => {
    if (direction === DiagramLineDirection.LeftToRight) {
      return {
        x1: selectorLineDeltaX,
        y1: startItem.offsetTop + startItem.offsetHeight,
        x2: startItemParent.offsetLeft + startItemParent.offsetWidth,
        y2: startItem.offsetTop + startItem.offsetHeight,
      };
    } else {
      return {
        x1: controlContainer.offsetLeft + controlContainer.clientWidth + selectorLineDeltaX,
        y1: startItem.offsetTop + startItem.offsetHeight,
        x2: startItemParent.offsetLeft,
        y2: startItem.offsetTop + startItem.offsetHeight,
      };
    }
  };

  const calculateConnectorLine = (): DiagramLineSettings => {
    if (direction === DiagramLineDirection.LeftToRight) {
      return {
        x1: startItemParent.offsetLeft + startItemParent.offsetWidth + connectorLineDeltaX,
        y1: startItem.offsetTop + startItem.offsetHeight,
        x2: endItem.offsetLeft + endItemParent.offsetLeft,
        y2: endItem.offsetTop + endItem.offsetHeight / 2,
      };
    } else {
      return {
        x1: startItemParent.offsetLeft - connectorLineDeltaX,
        y1: startItem.offsetTop + startItem.offsetHeight,
        x2: endItem.offsetLeft + endItem.offsetWidth + endItemParent.offsetLeft,
        y2: endItem.offsetTop + endItem.offsetHeight / 2,
      };
    }
  };

  const selectorLine = calculateSelectorLine();
  const connectorLine = calculateConnectorLine();

  return (
    <g className="bt-group bt-stroke-cline bt-stroke-1 bt-transition-[100ms] hover:bt-stroke-[4px] hover:[stroke-dasharray:8]">
      <line {...selectorLine} />
      <line {...connectorLine} />
      {userHasPermissions && (
        <>
          <AddPlus
            className="bt-invisible group-hover:bt-visible"
            x={controlContainer.offsetLeft + controlContainer.clientWidth / 2}
            y={startItem.offsetTop + startItem.clientHeight / 2 + 30}
          />
          <line
            {...selectorLine}
            className="bt-h-[100px] bt-cursor-pointer bt-stroke-transparent bt-stroke-[40] [stroke-dasharray:0]"
            onClick={onClick}
          />
        </>
      )}
    </g>
  );
};

export default ButterflyDiagramLine;
