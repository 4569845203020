import { v4 as uuid4 } from 'uuid';
import {
  aiApi,
  BowtieFactorsPostApiArg,
  BowtieFactorsPostApiResponse,
  ControlWithId,
  Detail,
} from '../generated/ai-api';

export const enhancedAiApi = aiApi.enhanceEndpoints({
  endpoints: {
    bowtieFactorsPost: {
      transformResponse: (response: BowtieFactorsPostApiResponse, _, args: BowtieFactorsPostApiArg) => {
        // align the shape of the data with the existing bowtie data

        const causes = response.causes.map((cause) => {
          const id = uuid4();

          const causes = cause.controls.map((control: ControlWithId) => {
            return {
              ...control,
              value: control.name,
              parentId: id,
            };
          });

          return {
            ...cause,
            id: id,
            uuid: id,
            value: cause.name,
            controls: [...causes],
            preventativeControls: [...causes],
          };
        });

        const consequences = response.consequences.map((consequence) => {
          const id = uuid4();

          const consequences = consequence.controls.map((control: ControlWithId) => {
            return {
              ...control,
              value: control.name,
              parentId: id,
            };
          });

          return {
            ...consequence,
            id: id,
            uuid: id,
            value: consequence.name,
            controls: [...consequences],
            mitigatingControls: [...consequences],
          };
        });

        return { causes, consequences, hazard: response.hazard ?? args.factorRequest.hazard ?? 'Hazard' } as Detail;
      },
    },
  },
});

export const { useLazyBowtieFactorsPostQuery } = enhancedAiApi;
