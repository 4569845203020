import React from 'react';

// Main area container
interface MainAreaContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

export const MainAreaContainer = ({ children }: MainAreaContainerProps) => {
  return (
    <div
      id="main-area-container"
      className="main-area-container bt-relative bt-flex bt-flex-col bt-items-center bt-justify-evenly bt-px-32 bt-py-0"
    >
      {children}
    </div>
  );
};

// Hazard container
interface HazardContainerProps {
  children: React.ReactNode;
}

export const HazardContainer = ({ children }: HazardContainerProps) => {
  return <div className="hazard-container bt-absolute bt-top-0">{children}</div>;
};

// Risk scenario container
interface RiskScenarioContainerProps {
  children: React.ReactNode;
}

export const RiskScenarioContainer = ({ children }: RiskScenarioContainerProps) => {
  return <div className="risk-scenario-container">{children}</div>;
};
