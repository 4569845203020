import Controls, { ContainerType } from '../../../../components/controls.component';
import diagramActions from '../../../../modules/diagram/diagramActions';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import userSelectors from '../../../../modules/user/userSelectors';
import LoadingPreview from '../loading-preview';

interface CausesProps {
  isLoading: boolean;
}

const Causes = ({ isLoading }: CausesProps) => {
  const dispatch = useAppDispatch();
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const canCreateCauses = userWithPermissions?.permissions?.createRecord?.causes;

  const addCauseInput = () => {
    dispatch(diagramActions.doAddCauseInput());
  };

  return (
    <Controls
      id="causes-container"
      type={ContainerType.CAUSES}
      onAdd={isLoading || !canCreateCauses ? undefined : addCauseInput}
    >
      {isLoading ? (
        <LoadingPreview message="Causes will be displayed here" />
      ) : canCreateCauses ? (
        'Add causes in this box'
      ) : (
        'Causes will be displayed here'
      )}
    </Controls>
  );
};

export default Causes;
