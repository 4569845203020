import queryString from 'query-string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'
import Caret from '../assets/images/Caret.png';
import { useConfigurationContext } from '../context/configuration.context';

import { isSuccessResponse } from '../helpers/type-guards';
import { cn } from '../helpers/util';
import diagramSelectors from '../modules/diagram/diagramSelectors';
import DiagramService from '../modules/diagram/diagramService';
import { DIAGRAM_FETCH_SCENARIO_RECORD_MORE } from '../modules/diagram/diagramTypes';
import { useAppDispatch, useAppSelector } from '../modules/hooks';
import LoadingControls from './loading-controls.component';

const diagramService = new DiagramService();
const riskScenarioPageSize = 50;

let page = 2;
let isScrolling = false;
let initialRecords = true;

interface DropDownRecordItem {
  value?: number;
  label?: string;
  selected: boolean;
  itemColor?: string | null;
}

const DropDown = () => {
  const bowtieConfiguration = useConfigurationContext();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { records, loading, isLastPage } = useAppSelector(diagramSelectors.selectScenarioRecords);
  const scenario = useAppSelector(diagramSelectors.selectScenarioRecords);

  const [open, setOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [, setRecordState] = useState<Array<DropDownRecordItem>>([]);

  const myStateRef = useRef(records);
  const dropDownRef = useRef<HTMLUListElement>(null);

  const queryParams = new URLSearchParams(location.search);

  const onToggle = useCallback(() => {
    setOpen(!open);
    setInitialLoad(false);
  }, [open]);

  useEffect(() => {
    document.addEventListener('click', (event: MouseEvent) => {
      if ((event.target as HTMLElement).contains(dropDownRef.current)) {
        setOpen(false);
      }
    });

    return document.removeEventListener('click', onToggle);
  }, [onToggle, open]);

  const handleFetchMoreRecords = useCallback(async () => {
    if (initialRecords) {
      myStateRef.current = records;
    }

    const response = await diagramService.fetchRecordsByFormId({
      formId: bowtieConfiguration.forms.main.id,
      pageSize: riskScenarioPageSize,
      page,
      isNewRiskScenario: false,
    });

    let hits = 0;

    if (isSuccessResponse(response)) {
      hits = (response.payload.data as any).result?.hits;
    }

    page++;

    if (isSuccessResponse(response) && hits > 0) {
      const transformRecords: Array<DropDownRecordItem> = await diagramService.doTransformScenarioRecords(
        response,
        bowtieConfiguration
      );

      if (initialRecords) {
        const newRecords: Array<DropDownRecordItem> = [...records, ...transformRecords];
        myStateRef.current = newRecords;
        setRecordState(newRecords);

        dispatch({
          type: DIAGRAM_FETCH_SCENARIO_RECORD_MORE,
          payload: {
            records: newRecords,
            isLastPage: hits < riskScenarioPageSize,
          },
        });
      } else {
        setRecordState((prevState) => {
          const newRecords = [...prevState, ...transformRecords];

          dispatch({
            type: DIAGRAM_FETCH_SCENARIO_RECORD_MORE,
            payload: {
              records: newRecords,
              isLastPage: hits < riskScenarioPageSize,
            },
          });

          return newRecords;
        });
      }
      isScrolling = false;
      initialRecords = false;
    } else {
      setRecordState((prevState) => {
        const records = initialRecords ? (scenario?.records ?? []) : prevState;
        dispatch({
          type: DIAGRAM_FETCH_SCENARIO_RECORD_MORE,
          payload: {
            records,
            isLastPage: true,
          },
        });

        return prevState;
      });
    }
  }, [bowtieConfiguration, dispatch, records, scenario?.records]);

  const handleScrollEvent = useCallback(
    async (event: Event) => {
      const element = event.target as HTMLElement;
      if (open && element.id === 'dropdown-scenario' && !isScrolling) {
        const isLastScroll = element.scrollHeight - Math.round(element.scrollTop);
        const elementHeight = element.clientHeight;

        if (isLastScroll <= elementHeight + 300) {
          isScrolling = true;
          await handleFetchMoreRecords();
        }
      }
    },
    [handleFetchMoreRecords, open]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent, true);

    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, [handleScrollEvent]);

  const openRecord = (id: string) => {
    const parsedSearch = queryString.parse(window.location.search);
    queryParams.delete('formId');
    queryParams.delete('moduleId');
    queryParams.set('records', id);
    const urlParsed = queryParams.toString();

    parsedSearch.records = id;
    window.open(window.location.origin + '?' + urlParsed.toString());
  };

  return (
    <div className="bt-relative bt-z-[3000] bt-block bt-h-[46px] bt-w-[400px]">
      <ul
        id="dropdown-scenario"
        className={cn(
          { 'bt-border-2 bt-border-gray-2': !open },
          'bt-absolute bt-left-0 bt-top-0 bt-z-[3000] bt-flex bt-max-h-[350px] bt-w-full bt-flex-col bt-items-center bt-overflow-auto bt-rounded-sm bt-bg-gray-5 bt-px-4 bt-py-0 bt-duration-[50ms]',
          'custom-scroll'
        )}
        ref={dropDownRef}
      >
        {loading && initialLoad ? (
          <EmptyDropDown open={open} label="New Diagram" />
        ) : records?.length > 0 ? (
          records?.map((dropdownItem: DropDownRecordItem) => {
            if (dropdownItem?.selected) {
              return (
                <li
                  className={cn(
                    'bt-sticky bt-left-0 bt-top-0 bt-flex bt-w-full bt-cursor-pointer bt-items-center bt-border-b bt-border-b-gray-2 bt-bg-gray-5 bt-px-0 bt-py-[5px] bt-text-base bt-font-bold bt-text-gray-2 hover:bt-text-gray-2',
                    { 'bt-border-none': !open }
                  )}
                  onClick={onToggle}
                  title={dropdownItem.label}
                  key={dropdownItem.value}
                >
                  <div
                    className="bt-mr-4 bt-h-[7px] bt-w-[7px] bt-rounded-[50%]"
                    style={{ background: dropdownItem?.itemColor ?? '' }}
                  ></div>
                  <div className="bt-max-w-[89%] bt-overflow-hidden bt-text-ellipsis bt-whitespace-nowrap bt-leading-8">
                    {dropdownItem.label}
                  </div>
                  <div className={cn('bt-absolute bt-right-0', { '[transform:rotateZ(180deg)]': !open })}>
                    <img src={Caret} alt="caret"></img>
                  </div>
                </li>
              );
            }

            if (open) {
              return (
                <li
                  className="bt-flex bt-w-full bt-cursor-pointer bt-items-center bt-px-0 bt-py-[5px] bt-text-sm bt-font-normal bt-text-gray-1 hover:bt-text-gray-2"
                  onClick={() => openRecord(String(dropdownItem.value))}
                  title={dropdownItem.label}
                  key={dropdownItem.value}
                >
                  <div
                    className="bt-mr-4 bt-h-[7px] bt-w-[7px] bt-rounded-[50%]"
                    style={{ background: dropdownItem?.itemColor ?? '' }}
                  ></div>
                  <div className="bt-max-w-[89%] bt-overflow-hidden bt-text-ellipsis bt-whitespace-nowrap bt-leading-8">
                    {dropdownItem.label}
                  </div>
                </li>
              );
            }
          })
        ) : (
          <EmptyDropDown open={open} label="No Diagram" />
        )}

        {open && !isLastPage && (
          <div className="bt-mt-8">
            <LoadingControls />
          </div>
        )}
      </ul>
    </div>
  );
};

export default DropDown;

// EmptyDropDown
interface EmptyDropDownProps {
  label: string;
  open: boolean;
}

const EmptyDropDown = ({ open, label }: EmptyDropDownProps) => {
  return (
    <li
      className="bt-sticky bt-left-0 bt-top-0 bt-flex bt-w-full bt-cursor-default bt-items-center bt-border-none bt-bg-gray-5 bt-px-0 bt-py-[5px] bt-text-base bt-font-bold bt-text-gray-1 bt-opacity-60"
      title={label}
    >
      <div className="bt-mr-4 bt-h-[7px] bt-w-[7px] bt-rounded-[50%] bt-bg-mono-1"></div>
      <div className="bt-max-w-[89%] bt-overflow-hidden bt-text-ellipsis bt-whitespace-nowrap bt-leading-8">
        {label}
      </div>
      <div className={cn('bt-absolute bt-right-0', { '[transform:rotateZ(180deg)]': !open })}>
        <img src={Caret} alt="caret"></img>
      </div>
    </li>
  );
};
