import { cn } from '../../../helpers/util';

interface AddPlusProps {
  x: number;
  y: number;
  className?: string;
}

const AddPlus = ({ x, y, className }: AddPlusProps) => {
  const plusStyle = cn(
    'bt-relative bt-z-[5000] bt-cursor-pointer bt-stroke-cline bt-stroke-[3px] [stroke-dasharray:0]',
    className
  );

  return (
    <g id="add-plus">
      <circle cx={x} cy={y} r="10" className={cn(plusStyle, 'bt-fill-mono-1')} />
      <line x1={x} y1={y - 7} x2={x} y2={y + 7} className={plusStyle} />
      <line x1={x - 7} y1={y} x2={x + 7} y2={y} className={plusStyle} />
    </g>
  );
};

export default AddPlus;
