import { RequestReturnError, RequestReturnSuccess } from '../helpers/request';
import { BowtieStateCauseData, BowtieStateConsequenceData } from '../services/bowtie-data-types';

export function isSuccessResponse<T>(
  response: RequestReturnSuccess<T> | RequestReturnError
): response is RequestReturnSuccess<T> {
  return response.success;
}

export function isErrorResponse<T>(
  response: RequestReturnSuccess<T> | RequestReturnError
): response is RequestReturnError {
  return !response.success;
}

export function isObject(object: unknown): object is Record<string, unknown> {
  return typeof object === 'object' && object !== null && Object.keys(object).length > 0;
}

export function isBowtieCauseData(value: unknown): value is BowtieStateCauseData {
  return isObject(value) && value.hasOwnProperty('preventativeControls');
}

export function isBowtieConsequenceData(value: unknown): value is BowtieStateConsequenceData {
  return isObject(value) && value.hasOwnProperty('mitigatingControls');
}
