import { AxiosRequestConfig, Method } from 'axios';
import { ParsedUrlQueryInput } from 'node:querystring';
import queryString from 'query-string';
import { EndPointSettings, keys } from '../helpers/config';
import Request from '../helpers/request';
import { buildUrl } from '../helpers/urlManager';

export class ServiceBase {
  protected readonly authorizationHeader: string | null;
  protected readonly envConfig: EndPointSettings;
  protected readonly parsedQueryString: queryString.ParsedQuery;

  constructor(protected env: string) {
    this.envConfig = keys[env];
    this.authorizationHeader = localStorage.getItem('idToken');
    this.parsedQueryString = queryString.parse(window.location.search);
  }

  protected createEndpointUrl(url: string, queryObject: ParsedUrlQueryInput = {}) {
    return buildUrl({ env: this.env, endpoint: url }, queryObject);
  }

  /**
   * Submits an API request using Axios and return either a success or error response.
   * @param {string} url - The API path to make the request on.
   * @param {Method} method - The HTTP method used with the request.
   * @param {Request extends undefined ? Response : Request} data - For POST, PUT or PATCH request this is the data to send as part of those requests.
   * @returns {Promise<RequestReturnSuccess<T> | RequestReturnError>}
   * @protected
   */
  protected performApiRequest<Response = unknown, Request = undefined>(
    url: string,
    method: Method,
    data?: Request extends undefined ? Response : Request
  ) {
    const finalOptions: AxiosRequestConfig = {
      url,
      method,
      data,
      headers: {
        Authorization: 'Bearer ' + this.authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
    };
    return Request.doRequest<Response>(finalOptions);
  }
}
