import { IconButton, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import { useNavigate } from 'react-router-dom';

const ReloadButton = () => {
  const navigate = useNavigate();

  const handleClick = () => navigate(0);

  return (
    <div title="Reload" className="bt-flex bt-cursor-pointer bt-items-center bt-justify-center bt-text-primary-2">
      <IconButton onClick={handleClick}>
        <OdinIcon size={OdinIconSize.Small} type={OdinIconType.Line} icon="Refresh" />
      </IconButton>
    </div>
  );
};

export default ReloadButton;
