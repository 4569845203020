import React from 'react';
import { cn } from '../../../helpers/util';
import { ContainerType } from '../../controls.component';

interface GeneralContainerProps {
  id: string;
  type: ContainerType;
  children: React.ReactNode;
}

export default function GeneralContainer({ id, type, children }: GeneralContainerProps) {
  const backgroundClassName = cn(
    { 'bt-bg-causes-bg': type === ContainerType.CAUSES },
    { 'bt-bg-consequences-bg': type === ContainerType.CONSEQUENCES },
    { 'bt-bg-pc-bg': type === ContainerType.PREVENTATIVE_CONTROLS },
    { 'bt-bg-mc-bg': type === ContainerType.MITIGATING_CONTROLS }
  );

  const borderClassName = cn(
    { 'bt-border-causes-border': type === ContainerType.CAUSES },
    { 'bt-border-consequences-border': type === ContainerType.CONSEQUENCES },
    { 'bt-border-pc-border': type === ContainerType.PREVENTATIVE_CONTROLS },
    { 'bt-border-mc-border': type === ContainerType.MITIGATING_CONTROLS }
  );

  return (
    <div id={`ga-${id}`} className="bt-group">
      <div
        id={id}
        className={cn(
          'bt-relative bt-mr-4 bt-mt-[29px] bt-max-h-max bt-rounded-lg bt-border-2 bt-border-dashed bt-px-[30px] bt-py-[18px] bt-text-center group-last:bt-mr-0',
          backgroundClassName,
          borderClassName
        )}
      >
        <h4 className="bt-mb-16 bt-font-bold">{type}</h4>
        {children}
      </div>
    </div>
  );
}
