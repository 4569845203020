import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './base-api';

const AI_API_SUFFIX = 'services/ai/v1';

export const aiApi = createApi({
  reducerPath: 'aiApi',
  baseQuery: (args, api, extraOptions) => baseQueryWithReAuth(args, api, { ...extraOptions, apiSuffix: AI_API_SUFFIX }),
  endpoints: () => ({}),
});
