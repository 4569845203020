import { Login } from '@myosh/myosh-login';
import { LoginResult } from '@myosh/myosh-login/dist/types/components/login.component';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LoginPage() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const username = searchParams.get('userName') || searchParams.get('username') || undefined;

  const onLoginSuccess = (results: LoginResult) => {
    if (results) {
      localStorage.setItem('idToken', results.idToken);
      localStorage.setItem('refreshToken', results.refreshToken);
      localStorage.setItem('schema', results.selectedSchema);
    }

    const storedInitialParams = localStorage.getItem('initialParams');
    navigate(`/${storedInitialParams ? storedInitialParams : ''}`);
  };

  return <Login onLoginSuccess={onLoginSuccess} username={username} />;
}
