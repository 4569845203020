import { compact, uniq } from 'lodash';
import queryString from 'query-string';
import { DynamicFormSettings } from '../@types/form-data-types';
import { RequestReturnSuccess } from '../helpers/request';
import { isSuccessResponse } from '../helpers/type-guards';
import { BowtieConfiguration } from './common-data-types';
import { RecordResult, RecordsWrapper, ScenarioRecord } from './record-data-types';
import { ServiceBase } from './service-base';

export class RecordService extends ServiceBase {
  constructor(env: string) {
    super(env);
  }

  public addControl(
    controlId: string,
    controlValue: string,
    controlFormId: number,
    controlModuleId: number,
    parentControlId: number,
    controlCaptionField: string,
    controlsForm: DynamicFormSettings,
    isPreventative: boolean
  ) {
    const dynamicFields: Record<string, unknown> = isPreventative
      ? {
          'Preventative/Mitigating': {
            value: 'Preventative',
          },
          Causes: [String(parentControlId)],
        }
      : {
          'Preventative/Mitigating': {
            value: 'Mitigating',
          },
          Consequences: [String(parentControlId)],
        };
    const workflowSteps = controlsForm.workflowSteps;

    const data: Array<RecordResult> = [
      {
        sourceId: controlId,
        formId: controlFormId,
        moduleId: controlModuleId,
        hierarchies: {},
        fields: {
          [controlCaptionField]: controlValue,
          ...dynamicFields,
        },
        status: workflowSteps ? workflowSteps.find((step) => step.draft === true)?.label || 'Draft' : 'Draft',
      },
    ];

    const requestUrl = this.createEndpointUrl('records/simple');
    return this.performApiRequest<RecordsWrapper, Array<RecordResult>>(requestUrl, 'POST', data);
  }

  public updateControl(
    controlId: string,
    controlValue: string,
    controlFormId: number,
    controlModuleId: number,
    parentControlId: number,
    controlCaptionField: string,
    existingControl: RecordResult,
    isPreventative: boolean
  ) {
    const dynamicFields: Record<string, unknown> = isPreventative
      ? {
          Causes: uniq([...((existingControl.fields['Causes'] as Array<unknown>) || []), String(parentControlId)]),
        }
      : {
          Consequences: uniq([
            ...((existingControl.fields['Consequences'] as Array<unknown>) || []),
            String(parentControlId),
          ]),
        };

    const data: Partial<RecordResult> = {
      id: Number(controlId),
      formId: controlFormId,
      moduleId: controlModuleId,
      hierarchies: {},
      fields: {
        [controlCaptionField]: controlValue,
        ...dynamicFields,
      },
      status: existingControl.status,
    };

    const requestUrl = this.createEndpointUrl(`records/simple/${controlId}`);
    return this.performApiRequest<RecordsWrapper, Partial<RecordResult>>(requestUrl, 'PUT', data);
  }

  public unlinkControl(
    controlId: string,
    controlFormId: number,
    controlModuleId: number,
    parentControlId: number,
    existingControl: RecordResult,
    isPreventative: boolean
  ) {
    const controlLinks = (
      isPreventative ? existingControl.fields['Causes'] : existingControl.fields['Consequences']
    ) as Array<string>;
    const updatedFields: Record<string, unknown> = isPreventative
      ? {
          Causes: controlLinks.filter((link) => link !== String(parentControlId)),
        }
      : {
          Consequences: controlLinks.filter((link) => link !== String(parentControlId)),
        };

    const data: Partial<RecordResult> = {
      id: Number(controlId),
      formId: controlFormId,
      moduleId: controlModuleId,
      hierarchies: {},
      fields: {
        ...updatedFields,
      },
      status: existingControl.status,
    };

    const requestUrl = this.createEndpointUrl(`records/simple/${controlId}`);
    return this.performApiRequest<RecordsWrapper, Partial<RecordResult>>(requestUrl, 'PATCH', data);
  }

  public addCauseConsequence(
    value: string,
    formId: number,
    moduleId: number, // NOTE: moduleId is not required when creating a record
    captionField: string,
    form: DynamicFormSettings,
    riskScenarioId: number
  ) {
    const requestUrl = this.createEndpointUrl('records/simple');

    const workflowSteps = form.workflowSteps;

    const data: Array<RecordResult> = [
      {
        formId,
        moduleId,
        hierarchies: {},
        fields: {
          [captionField]: value,
          Risk: [String(riskScenarioId)],
        },
        status: workflowSteps
          ? (workflowSteps.find((step) => step.draft === true)?.label ?? workflowSteps[0].label)
          : '',
      },
    ];

    return this.performApiRequest<RecordsWrapper, Array<RecordResult>>(requestUrl, 'POST', data);
  }

  public setControlIdsToRiskScenarioRecord(
    recordId: number,
    status: string,
    formId: number,
    preventativeControlIds: Array<string>,
    mitigatingControlIds: Array<string>,
    hazardCaptionField: string,
    hazard: string
  ) {
    const requestUrl = this.createEndpointUrl('records/simple');

    const fields = {
      'Preventative Controls': [...preventativeControlIds],
      'Mitigating Controls': [...mitigatingControlIds],
      [hazardCaptionField]: hazard,
    };

    const data = [
      {
        id: recordId,
        status,
        formId,
        fields,
        hierarchies: {},
      },
    ];

    return this.performApiRequest<RecordsWrapper, Array<RecordResult>>(requestUrl, 'PATCH', data);
  }

  public fetchRecord(recordId: number) {
    const requestUrl = this.createEndpointUrl(`records/simple/${recordId}`);
    return this.performApiRequest<RecordsWrapper>(requestUrl, 'GET');
  }

  public fetchRecords(formId: number, recordIds: Array<number>) {
    const requestUrl = this.createEndpointUrl(`forms/${formId}/records/simple`, {
      filter: `id:in:${compact(recordIds).join(',')}`,
    });
    return this.performApiRequest<RecordsWrapper>(requestUrl, 'GET');
  }

  public fetchFilteredRecordsByFormId(formId: number, filter: string) {
    const requestUrl = this.createEndpointUrl(`forms/${formId}/records/simple`, {
      filter,
    });
    return this.performApiRequest<RecordsWrapper>(requestUrl, 'GET');
  }

  public async fetchScenarioRecordsByFormId(formId: number, isNewRiskScenario: boolean, page = 1, pageSize = 50) {
    const parsedSearch = queryString.parse(window.location.search);
    const isEqual = isNewRiskScenario ? 'eq' : 'neq';
    const requestUrl = this.createEndpointUrl(`forms/${formId}/records/simple`, {
      page,
      pageSize,
      filter: !parsedSearch?.records ? '' : `id:${isEqual}:${parsedSearch?.records}`,
      sort: 'Risk Scenario',
      order_by: 'ASC',
    });

    return this.performApiRequest<RecordsWrapper>(requestUrl, 'GET');
  }

  public async filterScenarioRecords(
    record: RecordResult,
    bowtieConfiguration: BowtieConfiguration,
    isNewRiskScenario = false
  ) {
    const bowtieRecordsPayload = await this.fetchScenarioRecordsByFormId(record.formId, isNewRiskScenario);
    const { scenario: riskScenario } = bowtieConfiguration;

    if (isSuccessResponse(bowtieRecordsPayload)) {
      const form = bowtieConfiguration.forms.main.form;
      let records = this.transformScenarioRecords(bowtieRecordsPayload, riskScenario.captionField, form);
      const selectedRecords = records.filter((record) => record.selected);
      records = records.filter((record) => !record.selected);

      if (selectedRecords.length > 0) {
        records.unshift(...selectedRecords);
      } else {
        records.unshift({
          value: record.id,
          label: record?.fields[riskScenario.captionField] as string,
          selected: true,
          itemColor: '#0ba600',
        });
      }

      return records;
    }
  }

  public transformScenarioRecords(
    bowtieRecords: RequestReturnSuccess<RecordsWrapper>,
    riskScenarioCaption: string,
    form: DynamicFormSettings
  ) {
    const results = bowtieRecords.payload.data.result.results;

    return results
      .filter((result) => result.fields)
      .map<ScenarioRecord>((result) => {
        return {
          value: result.id,
          label: result.fields[riskScenarioCaption] as string,
          selected: result.id === Number(this.parsedQueryString.records),
          itemColor:
            form.workflowSteps && form.workflowSteps.length > 0
              ? form.workflowSteps.find((workflowStep) => workflowStep.label === result.status)?.backgroundColor
              : null,
        };
      })
      .filter((result) => result.label);
  }
}
