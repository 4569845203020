import React, { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAIContext } from '../../../context/ai.context';
import { useConfigurationContext } from '../../../context/configuration.context';
import { cn } from '../../../helpers/util';
import diagramActions from '../../../modules/diagram/diagramActions';
import recordSelectors from '../../../modules/diagram/diagramSelectors';
import { useAppDispatch, useAppSelector } from '../../../modules/hooks';
import userSelectors from '../../../modules/user/userSelectors';
import { BowtieStateData } from '../../../services/bowtie-data-types';
import { ContainerType } from '../../controls.component';
import { DiagramStatus } from './hazard';
import LoadingPreview from './loading-preview';
import Rectangle from './rectangle/rectangle';

interface RiskScenarioProps {
  mode: DiagramStatus;
  isLoading: boolean;
  defaultRiskScenario?: string;
  hasCircleLeft?: boolean;
  hasCircleRight?: boolean;
  link?: string;
  showHazard: () => void;
  hazardInputRef: React.RefObject<HTMLTextAreaElement>;
  bowtieData?: BowtieStateData;
}

const RiskScenario = (
  {
    defaultRiskScenario,
    isLoading = false,
    hasCircleLeft = false,
    hasCircleRight = false,
    link,
    mode,
    showHazard,
    hazardInputRef,
    bowtieData,
  }: RiskScenarioProps,
  forwardRef: Ref<HTMLTextAreaElement>
) => {
  const [editRiskScenario, setEditRiskScenario] = useState(DiagramStatus.NEW === mode);
  const [isEditRiskScenarioDisabled, setIsEditRiskScenarioDisabled] = useState(false);
  const [riskScenario, setRiskScenario] = useState(defaultRiskScenario);

  const isAIGenerated = useAIContext();
  const bowtieConfiguration = useConfigurationContext();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  useImperativeHandle(forwardRef, () => inputRef.current as HTMLTextAreaElement);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const userWithPermissions = useAppSelector(userSelectors.selectUser);
  const isRiskScenarioLoading = useAppSelector(recordSelectors.selectAddLoading);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const canEditRiskScenario = userWithPermissions?.permissions?.editRecord?.main;

  useEffect(() => {
    if (inputRef.current?.focus && editRiskScenario) {
      inputRef.current.focus();
    }
  }, [editRiskScenario]);

  const handleRectangleClick = () => {
    if (canEditRiskScenario) {
      setEditRiskScenario(true);
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !event.shiftKey) {
      event.preventDefault();
      if (inputRef.current?.value === '') {
        inputRef.current.focus();
      } else {
        inputRef.current?.blur();
      }
    }
  };

  const handleAddRiskScenario = () => {
    queryParams.delete('formId');
    queryParams.delete('moduleId');

    const urlParsed = queryParams.toString();

    if (inputRef.current && inputRef.current.value) {
      dispatch(
        diagramActions.doAddRiskScenario({
          payload: inputRef.current.value,
          urlParsed,
          hazardInputRef,
          bowtieConfiguration,
        })
      );

      showHazard();
    }
  };

  const handleUpdateRiskScenario = async () => {
    if (inputRef.current && inputRef.current.value !== '') {
      setIsEditRiskScenarioDisabled(true);

      if (inputRef.current?.value !== riskScenario) {
        const value = inputRef.current.value.trim();
        const shouldExecuteUpdate = isAIGenerated === false && bowtieData;

        setRiskScenario(value);
        setEditRiskScenario(false);
        setIsEditRiskScenarioDisabled(false);
        dispatch(diagramActions.doUpdateRiskScenarioValue(bowtieData, value, shouldExecuteUpdate));

        if (hazardInputRef?.current?.value === '') {
          hazardInputRef?.current?.focus();
        }
      } else {
        setEditRiskScenario(false);
        setIsEditRiskScenarioDisabled(false);
      }
    } else {
      setEditRiskScenario(false);
      setIsEditRiskScenarioDisabled(false);
    }
  };

  // resolve values based on new/edit mode
  const handleOnBlur = DiagramStatus.NEW === mode ? handleAddRiskScenario : handleUpdateRiskScenario;
  const textAreaDisabled = DiagramStatus.NEW === mode ? isRiskScenarioLoading : isEditRiskScenarioDisabled;

  const riskStyles = cn(
    'bt-relative bt-z-[3000] bt-flex bt-h-[96px] bt-w-[155px] bt-items-center bt-justify-center bt-rounded-lg bt-border-2 bt-border-dashed bt-border-primary-4 bt-bg-gray-5 bt-p-7 bt-text-center bt-text-gray-1 bt-outline-none'
  );

  // element
  if (isLoading) {
    return (
      <div className={cn(riskStyles, 'bt-mb-32 bt-resize-none bt-flex-col bt-text-sm')}>
        <LoadingPreview message="Risk will be displayed here" />
      </div>
    );
  } else if (riskScenario && !editRiskScenario) {
    return (
      <Rectangle
        id="risk-scenario"
        type={ContainerType.MUE}
        text={riskScenario}
        hasCircleRight={hasCircleLeft}
        hasCircleLeft={hasCircleRight}
        link={link}
        click={handleRectangleClick}
      />
    );
  } else {
    return (
      <textarea
        rows={2}
        id="risk-scenario"
        defaultValue={defaultRiskScenario}
        ref={inputRef}
        disabled={textAreaDisabled}
        onKeyDown={handleOnKeyDown}
        placeholder="Click to add Risk Scenario"
        onBlur={handleOnBlur}
        className={cn(riskStyles, 'bt-w-[205px] bt-resize-none bt-text-lg')}
      />
    );
  }
};

export default forwardRef(RiskScenario);
