import { BowtieStateData } from '../../../../services/bowtie-data-types';
import { ContainerType } from '../../../controls.component';
import GeneralContainer from '../general-container';
import MultiLineContainer, { CONTAINER_ALIGNMENT } from '../multi-line-container/multi-line-container';

interface ConsequencesExistingProps {
  bowtieData: BowtieStateData;
}

const ConsequencesExisting = ({ bowtieData }: ConsequencesExistingProps) => {
  return (
    <GeneralContainer id="consequences-container" type={ContainerType.CONSEQUENCES}>
      <MultiLineContainer
        id="consequences"
        type={ContainerType.CONSEQUENCES}
        mode="single"
        controlLines={bowtieData.consequences}
        form={bowtieData.bowtieConfiguration.forms.consequences}
        alignment={CONTAINER_ALIGNMENT.RIGHT}
      />
    </GeneralContainer>
  );
};

export default ConsequencesExisting;
