import { useEffect } from 'react';
import { useUser } from '@myosh/myosh-login';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ThemeProvider } from '../../context/theme.context';

function PrivateRoute() {
  const { state } = useUser();
  const isAuthenticated = state.auth;
  const location = useLocation();
  const initialParams = location.search;

  useEffect(() => {
    if (
      initialParams &&
      initialParams.length > 0 &&
      initialParams.includes('schemaName') &&
      initialParams.includes('records')
    ) {
      localStorage.setItem('initialParams', initialParams);
    }
  }, []);

  if (isAuthenticated) {
    return (
      <ThemeProvider>
        <Outlet />
      </ThemeProvider>
    );
  } else if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return null;
}

export default PrivateRoute;
