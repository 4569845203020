import { AuthService } from '@myosh/myosh-login';
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';

const USER_TIME_ZONE = Intl.DateTimeFormat(navigator.language).resolvedOptions().timeZone;

const notifyUserAndLogout = (id: string, message: string) => {
  toast.error(message, { autoClose: 3000 });

  const authService = new AuthService();
  setTimeout(() => authService.logout(), 3200);
};

/**
 * Returns the base URL for making API requests.
 *
 * Checks if a custom cluster API URL is set in localStorage
 * and uses that if available, otherwise uses the .env variable.
 *
 * The apiSuffix param specifies if we are using the base v4 api (default), or a service API such as /ai for example.
 *
 * @param apiSuffix - Optional API suffix specifier
 *
 * @returns The base URL string for API requests
 */
const resolveBasePath = (apiSuffix: string) => {
  const clusterConfig = JSON.parse(localStorage.getItem('ClusterConfig') || '{}');
  if (clusterConfig.apiBaseUrl) {
    return `${clusterConfig.apiBaseUrl}${apiSuffix}`;
  }

  return `${process.env.REACT_APP_API_BASE_URL}${apiSuffix}`;
};

/**
 * Returns the API key to use for API requests.
 *
 * Checks if a custom cluster API key is set in localStorage
 * and uses that if available, otherwise uses the .env variable.
 */
const resolveApiKey = () => {
  const clusterConfig = JSON.parse(localStorage.getItem('ClusterConfig') || '{}');
  if (clusterConfig.apiKey) {
    return clusterConfig.apiKey;
  }
  return `${process.env.REACT_APP_API_KEY}`;
};

const baseQuery = (apiUrl: string, apiKey: string) =>
  fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers) => {
      if (localStorage.getItem('UserState')) {
        const userState = JSON.parse(localStorage.getItem('UserState') || '{}');
        const schema = userState?.user?.currentSchema;
        const idToken = userState?.tokens?.id_token;
        const superUser = userState?.user?.superuser === 'true';
        const controlledUsername = userState?.user?.controlledUsername;

        if (idToken) {
          headers.set('authorization', `Bearer ${idToken}`);
        }
        if (schema) {
          headers.set('Myosh-Schema', schema);
        }
        if (superUser && controlledUsername) {
          headers.set('Controlled-Username', controlledUsername);
        }
      }
      headers.set('x-api-key', apiKey);
      headers.set('Myosh-TimeZone', USER_TIME_ZONE);

      return headers;
    },
  });

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const apiSuffix = extraOptions && 'apiSuffix' in extraOptions ? String(extraOptions.apiSuffix) : '';
  const apiBaseUrl = resolveBasePath(apiSuffix);
  const apiKey = resolveApiKey();

  if (apiBaseUrl && apiKey) {
    let result = await baseQuery(apiBaseUrl, apiKey)(args, api, extraOptions);

    // handle re-auth with refreshToken
    if (result.error) {
      if (result.error.status === 401) {
        const authService = new AuthService();
        const refreshAuth = await authService.signinSilent();
        if (refreshAuth.refresh_token) {
          const userState = JSON.parse(localStorage.getItem('UserState') || '{}');
          userState.tokens = {
            ...userState.tokens,
            id_token: refreshAuth.id_token,
            access_token: refreshAuth.access_token,
            refresh_token: refreshAuth.refresh_token,
            expires_at: refreshAuth.expires_at,
            auth_time: Date.now(),
          };
          localStorage.setItem('UserState', JSON.stringify(userState));
          result = await baseQuery(apiBaseUrl, apiKey)(args, api, extraOptions);
        } else {
          notifyUserAndLogout('401', 'Your session has expired, please sign in again.');
        }
      }
    }

    return result;
  } else {
    notifyUserAndLogout('400', 'The request cannot be served as the cluster configuration information is missing');

    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'Missing application configuration',
      },
    };
  }
};
