import { uniq } from 'lodash';
import queryString from 'query-string';
import { DynamicFormSettings } from '../../@types/form-data-types';
import { v4Api } from '../../api/generated/v4-api';
import { EndPointSettings, keys } from '../../helpers/config';
import Request from '../../helpers/request';
import { buildUrl } from '../../helpers/urlManager';
import {
  BowtieBasicRecord,
  BowtieStateCauseData,
  BowtieStateConsequenceData,
  BowtieStateData,
} from '../../services/bowtie-data-types';
import { BowtieConfiguration } from '../../services/common-data-types';
import { RecordResult } from '../../services/record-data-types';
import { AppDispatch } from '../hooks';

interface RiskScenarioPayloadType {
  formId: number;
  formName: string;
  status: string;
  linkedRecordId: number;
  fields: Record<string, unknown>;
  preventative?: boolean;
  mitigating?: boolean;
}

interface FetchRecordsByFormIdType {
  formId: number;
  isNewRiskScenario: boolean;
  page?: number;
  pageSize?: number;
}

export default class DiagramService {
  private readonly env: string;
  private readonly envConfig: EndPointSettings;

  constructor() {
    this.env = 'test';
    this.envConfig = keys['test'];
  }

  addRiskScenario(form: DynamicFormSettings, labelField: string, labelValue: string, draftStatus?: string) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple` }, {});

    let status = 'Draft';
    if (draftStatus) {
      status = draftStatus;
    } else if (form.workflowSteps && form.workflowSteps.length > 0) {
      status = form.workflowSteps.find((step) => step.draft === true)?.label ?? 'Default';
    }

    return Request.doRequest({
      url: requestUrl,
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: [
        {
          formId: form.id,
          hierarchies: {},
          fields: {
            [labelField]: labelValue,
          },
          status: status,
        },
      ],
    });
  }

  async updateRiskScenarioHazard(
    riskScenarioRecord: RecordResult,
    hazardFieldLabel: string,
    hazardFieldValue: string,
    dispatch: AppDispatch
  ) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: riskScenarioRecord.id as number,
        params: {},
        simpleRecordDto: {
          formId: riskScenarioRecord.formId,
          formName: riskScenarioRecord.formName,
          status: riskScenarioRecord.status,
          id: riskScenarioRecord.id,
          fields: {
            [hazardFieldLabel]: hazardFieldValue,
          },
        },
      })
    ).unwrap();
    return result;
  }

  updateRiskScenarioRecord(riskScenarioId: number, riskScenarioPayload: RiskScenarioPayloadType) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple/${riskScenarioId}` }, {});

    const editableFieldKey: string | undefined = Object.keys(riskScenarioPayload.fields).find((fieldKey: string) => {
      if (riskScenarioPayload.mitigating) {
        return fieldKey === 'Mitigating Controls';
      }

      if (riskScenarioPayload.preventative) {
        return fieldKey === 'Preventative Controls';
      }

      return fieldKey === 'Preventative Controls';
    });

    const fieldValue: Array<string> = editableFieldKey
      ? (riskScenarioPayload.fields[editableFieldKey] as string[])
      : [];

    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: {
        formId: riskScenarioPayload.formId,
        formName: riskScenarioPayload.formName,
        status: riskScenarioPayload.status,
        id: riskScenarioId,
        fields: {
          [riskScenarioPayload.mitigating ? 'Mitigating Controls' : 'Preventative Controls']: editableFieldKey
            ? uniq([...fieldValue, String(riskScenarioPayload.linkedRecordId)])
            : [String(riskScenarioPayload.linkedRecordId)],
        },
      },
    });
  }

  async updateRiskScenarioField(bowtieData: BowtieStateData, value: string, dispatch: AppDispatch) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: bowtieData.scenarioRecord.id as number,
        params: {},
        simpleRecordDto: {
          id: bowtieData.scenarioRecord.id,
          formId: bowtieData.scenarioRecord.formId,
          formName: bowtieData.scenarioRecord.formName,
          status: bowtieData.scenarioRecord.status,
          fields: {
            [bowtieData.bowtieConfiguration.scenario.captionField]: value,
          },
        },
      })
    );
    return result;
  }

  async updateCause(
    bowtieData: BowtieStateData,
    causeRecord: BowtieStateCauseData,
    value: string,
    dispatch: AppDispatch
  ) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: causeRecord.uuid as number,
        params: {},
        simpleRecordDto: {
          id: causeRecord.uuid,
          formId: bowtieData.bowtieConfiguration.forms.causes.id,
          status: causeRecord.status,
          fields: {
            [bowtieData.bowtieConfiguration.preventativeControls.causes.captionField]: value,
          },
        },
      })
    ).unwrap();
    return result;
  }

  async updateConsequence(
    bowtieData: BowtieStateData,
    consequenceRecord: BowtieStateConsequenceData,
    value: string,
    dispatch: AppDispatch
  ) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: consequenceRecord.uuid as number,
        params: {},
        simpleRecordDto: {
          id: consequenceRecord.uuid,
          formId: bowtieData.bowtieConfiguration.forms.consequences.id,
          status: consequenceRecord.status,
          fields: {
            [bowtieData.bowtieConfiguration.mitigatingControls.consequences.captionField]: value,
          },
        },
      })
    ).unwrap();
    return result;
  }

  async updatePreventativeControl(
    bowtieData: BowtieStateData,
    preventativeControlRecord: BowtieBasicRecord,
    value: string,
    dispatch: AppDispatch
  ) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: preventativeControlRecord.id as number,
        params: {},
        simpleRecordDto: {
          id: preventativeControlRecord.id,
          formId: bowtieData.bowtieConfiguration.forms.controls.id,
          status: preventativeControlRecord.status,
          fields: {
            [bowtieData.bowtieConfiguration.preventativeControls.captionField]: value,
          },
        },
      })
    ).unwrap();
    return result;
  }

  async updateMitigatingControl(
    bowtieData: BowtieStateData,
    mitigatingControlRecord: BowtieBasicRecord,
    value: string,
    dispatch: AppDispatch
  ) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: mitigatingControlRecord.id as number,
        params: {},
        simpleRecordDto: {
          id: mitigatingControlRecord.id,
          formId: bowtieData.bowtieConfiguration.forms.controls.id,
          status: mitigatingControlRecord.status,
          fields: {
            [bowtieData.bowtieConfiguration.mitigatingControls.captionField]: value,
          },
        },
      })
    ).unwrap();
    return result;
  }

  fetchRecordsByFormId(params: FetchRecordsByFormIdType) {
    const { formId, pageSize = 50, page = 1, isNewRiskScenario } = params;
    const authorizationHeader = localStorage.getItem('idToken');
    const parsedSearch = queryString.parse(window.location.search);
    const isEqual = isNewRiskScenario ? 'eq' : 'neq';
    const requestUrl = buildUrl(
      {
        env: this.env,
        endpoint: `forms/${formId}/records`,
      },
      {
        pageSize,
        page,
        filter: !parsedSearch?.records ? '' : `id:${isEqual}:${parsedSearch?.records}`,
        sort: 'Risk Scenario',
        order_by: 'ASC',
      }
    );

    return Request.doRequest({
      url: requestUrl,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
    });
  }

  async doTransformScenarioRecords(bowtieRecordsResponse: any, bowtieConfiguration: BowtieConfiguration) {
    const {
      scenario: riskScenario,
      forms: {
        main: { form },
      },
    } = bowtieConfiguration;

    const {
      payload: {
        data: {
          result: { results },
        },
      },
    } = bowtieRecordsResponse;

    // FIXME: the recordId should be passed as an argument to this method
    const parsedSearch = queryString.parse(window.location.search);

    return (results as Array<RecordResult>)
      .filter((result) => result.fields)
      .map((result: RecordResult) => {
        const fields = Array.isArray(result.fields) ? result.fields : [result.fields];
        const label = fields.find((field) => field.name === riskScenario?.captionField)?.value;
        return {
          value: result.id,
          label,
          selected: result.id === Number(parsedSearch.records),
          itemColor:
            form.workflowSteps && form.workflowSteps.length > 0
              ? form.workflowSteps.find((workflowStep) => {
                  return workflowStep.label === result.status;
                })?.backgroundColor
              : null,
        };
      })
      .filter((result) => result.label);
  }

  async doFetchScenarioRecords(params: FetchRecordsByFormIdType) {
    const { formId, isNewRiskScenario } = params;

    return await this.fetchRecordsByFormId({
      formId: formId,
      pageSize: 50,
      page: 1,
      isNewRiskScenario,
    });
  }

  async doFilterScenarioRecord(
    record: RecordResult,
    bowtieConfiguration: BowtieConfiguration,
    isNewRiskScenario = false
  ) {
    const bowtieRecordsPayload = await this.doFetchScenarioRecords({
      formId: record?.formId,
      isNewRiskScenario,
    });

    const { scenario: riskScenario } = bowtieConfiguration;

    if (bowtieRecordsPayload.success) {
      let records = await this.doTransformScenarioRecords(bowtieRecordsPayload, bowtieConfiguration);
      const selectedRecord = records.find((record) => record.selected);
      records = records.filter((record) => !record.selected);

      if (!!selectedRecord) {
        records.unshift(selectedRecord);
      } else {
        const fields = Array.isArray(record.fields) ? record.fields : [record.fields];
        const label = fields.find((field) => field.name === riskScenario.captionField)?.value;
        records.unshift({
          value: record.id,
          label,
          selected: true,
          itemColor: '#0ba600',
        });
      }

      return records;
    }
  }

  doUpdateRecord(recordId: number, recordPayload: RecordResult) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple/${recordId}` }, {});

    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      data: recordPayload,
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
    });
  }
}
