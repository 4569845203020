import { Loader } from '@myosh/odin-components';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetThemeQuery } from '../api/generated/v4-api';

type Theme = Record<string, string>;

interface ThemeContextProps {
  children: React.ReactNode;
}

const ThemeContext = createContext<Theme | undefined>(undefined);

export const ThemeProvider: React.FC<ThemeContextProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>();
  const [loaded, setLoaded] = useState(false);
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useGetThemeQuery();

  useEffect(() => {
    if (!isLoading && !isFetching) {
      if (data) {
        setTheme(data);
      } else {
        // unable to fetch theme, continue with default
        console.warn('Unable to load user theme, continuing with default app theme.');
        setLoaded(true);
      }
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (theme) {

      const keys = Object.keys(theme);
      for (let i = 0, length = keys.length; i < length; i++) {
        const color = theme[keys[i]];
        if (CSS.supports('color', color)) {
          document.documentElement.style.setProperty(`--${keys[i]}`, color);
        } else {
          console.warn(`The CSS color '${color}' is not supported.`);
        }
      }
      setLoaded(true);
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={theme}>
      {loaded ? (
        children
      ) : (
        <div className="bt-mx-auto bt-flex bt-h-screen bt-w-full bt-flex-col bt-items-center bt-justify-center">
          <Loader title={t('loading-theme')} />
        </div>
      )}
    </ThemeContext.Provider>
  );
};

// context consumer hook
export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useThemeContext must be used within ThemeProvider');
  }

  return context;
};
