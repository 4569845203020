import LoadingControls from '../../loading-controls.component';

interface LoadingPreviewProps {
  message: string;
}

const LoadingPreview = ({ message }: LoadingPreviewProps) => {
  return (
    <div className="bt-mt-6 bt-text-gray-1">
      <LoadingControls />
      {message}
    </div>
  );
};

export default LoadingPreview;
