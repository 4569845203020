import { compact } from 'lodash';
import { isObject, isSuccessResponse } from '../helpers/type-guards';
import {
  BowtieBasicRecord,
  BowtieCauseRecord,
  BowtieConsequenceRecord,
  BowtieMappedData,
  ControlCauseLink,
} from './bowtie-data-types';
import { BowtieConfiguration, BowtieMitigatingControls, BowtiePreventativeControls } from './common-data-types';
import { RecordResult } from './record-data-types';
import { RecordService } from './record-service';
import { ServiceBase } from './service-base';

export class BowtieService extends ServiceBase {
  private readonly requiredMitigatingControlsFields = [
    'captionField',
    'consequences',
    'criticalOrNonCriticalField',
    'effectiveOrNotEffectiveField',
    'recordlinkField',
  ];
  private readonly requiredPreventativeControlFields = [
    'captionField',
    'causes',
    'criticalOrNonCriticalField',
    'effectiveOrNotEffectiveField',
    'recordlinkField',
  ];

  private readonly recordService: RecordService;

  constructor(env: string) {
    super(env);

    this.recordService = new RecordService(env);
  }

  public async mapBowtieData(record: RecordResult, bowtieConfiguration: BowtieConfiguration) {
    const bowtieData: BowtieMappedData = {
      scenarioRecord: record,
    };

    if (!record.fields) {
      throw new Error("Missing parameter 'fields' in record body");
    }

    if (!bowtieConfiguration.scenario) {
      throw new Error("Missing parameter 'scenario' in bowtie configuration");
    }

    if (!bowtieConfiguration.mitigatingControls) {
      throw new Error("Missing parameter 'mitigatingControls' in bowtie configuration");
    }

    if (bowtieConfiguration.mitigatingControls) {
      const mitigatingControls = bowtieConfiguration.mitigatingControls as unknown as Record<string, unknown>;
      this.requiredMitigatingControlsFields.forEach((requiredField) => {
        if (!mitigatingControls[requiredField]) {
          throw new Error(`Missing parameter '${requiredField}' in 'mitigatingControls' field`);
        }
      });
    }

    if (!bowtieConfiguration.preventativeControls) {
      throw new Error("Missing parameter 'preventativeControls' in bowtie configuration");
    }

    if (bowtieConfiguration.preventativeControls) {
      const preventativeControls = bowtieConfiguration.preventativeControls as unknown as Record<string, unknown>;
      this.requiredPreventativeControlFields.forEach((requiredField) => {
        if (!preventativeControls[requiredField]) {
          throw new Error(`Missing parameter '${requiredField}' in 'preventativeControls' field`);
        }
      });
    }

    const { fields } = record;
    const { scenario, hazard, mitigatingControls, preventativeControls } = bowtieConfiguration;

    const scenarioField = fields[scenario.captionField];

    // Adding bowtie configuration
    bowtieData.bowtieConfiguration = bowtieConfiguration;

    bowtieData.scenario = scenarioField;

    if (hazard) {
      const hazardField = fields[hazard.captionField];
      bowtieData.hazard = hazardField ?? null;
    }

    const mitigatingControlRecordLinkField = fields[mitigatingControls.recordlinkField] as Array<unknown>;

    // Fetching all preventative controls with causes
    const preventativeControlsRecordLinkField = fields[preventativeControls.recordlinkField] as Array<unknown>;

    const bowtieDataPromisePool = await Promise.all([
      this.fetchMitigatingControlsWithConsequences(
        bowtieConfiguration,
        mitigatingControlRecordLinkField,
        mitigatingControls,
        fields
      ),
      this.fetchPreventativeControlsWithCauses(
        bowtieConfiguration,
        preventativeControlsRecordLinkField,
        preventativeControls,
        fields
      ),
    ]);

    const mitigatingControlRecords = bowtieDataPromisePool[0];
    const preventativeControlRecords = bowtieDataPromisePool[1];

    if (mitigatingControlRecords && mitigatingControlRecords.records) {
      bowtieData.mitigatingControls = [...compact(mitigatingControlRecords.records)];
    } else {
      bowtieData.mitigatingControls = [];
    }

    if (preventativeControlRecords && preventativeControlRecords.records) {
      bowtieData.preventativeControls = [...compact(preventativeControlRecords.records)];
    } else {
      bowtieData.preventativeControls = [];
    }

    const uniqueCauses = preventativeControlRecords?.causes || [];

    const uniqueConsequences = mitigatingControlRecords?.consequences || [];

    bowtieData.causes = uniqueCauses.map((uniqueCause) => {
      const ownPreventativeControls = bowtieData.preventativeControls?.filter(
        (preventativeControl) =>
          preventativeControl &&
          preventativeControl.causes &&
          preventativeControl.causes.length > 0 &&
          preventativeControl.causes.find((cause) => cause && cause && cause.id === uniqueCause.id)
      );

      return {
        ...uniqueCause,
        preventativeControls: ownPreventativeControls,
        form: bowtieConfiguration.forms.causes,
      };
    });

    bowtieData.consequences = uniqueConsequences.map((uniqueConsequence) => {
      const ownMitigatingControls = bowtieData.mitigatingControls?.filter(
        (mitigatingControl) =>
          mitigatingControl &&
          mitigatingControl.consequences &&
          mitigatingControl.consequences.length > 0 &&
          mitigatingControl.consequences.find(
            (consequence) => consequence && consequence && consequence.id === uniqueConsequence.id
          )
      );

      return {
        ...uniqueConsequence,
        mitigatingControls: ownMitigatingControls,
        form: bowtieConfiguration.forms.consequences,
      };
    });

    const standaloneCauses = localStorage.getItem('standaloneCauses' + '_' + window.location.search);
    const standaloneConsequences = localStorage.getItem('standaloneConsequences' + '_' + window.location.search);

    if (standaloneCauses) {
      const parsedStandaloneCauses = JSON.parse(standaloneCauses) as Array<BowtieBasicRecord>;
      const dataCauses = bowtieData.causes;
      bowtieData.causes = [
        ...dataCauses,
        ...(dataCauses
          ? parsedStandaloneCauses.filter(
              (cause) => dataCauses.findIndex((dataCause) => dataCause.id === cause.uuid) === -1
            )
          : parsedStandaloneCauses),
      ];
    }

    if (standaloneConsequences) {
      const parsedStandaloneConsequences = JSON.parse(standaloneConsequences) as Array<BowtieBasicRecord>;
      const dataConsequences = bowtieData.consequences;
      bowtieData.consequences = [
        ...dataConsequences,
        ...(dataConsequences
          ? parsedStandaloneConsequences.filter(
              (consequence) =>
                dataConsequences.findIndex((dataConsequence) => dataConsequence.id === consequence.uuid) === -1
            )
          : parsedStandaloneConsequences),
      ];
    }

    return bowtieData;
  }

  public async fetchMitigatingControlsWithConsequences(
    bowtieConfiguration: BowtieConfiguration,
    mitigatingControlRecordLinkField: Array<unknown>,
    mitigatingControls: BowtieMitigatingControls,
    scenarioRecordFields: Record<string, unknown>
  ) {
    // fetch the consequences
    const scenarioRecordLinkField = scenarioRecordFields[
      mitigatingControls.consequences.recordLinkField
    ] as Array<number>;

    const consequencesRecordPayload = await this.recordService.fetchRecords(
      bowtieConfiguration.forms?.consequences?.id,
      scenarioRecordLinkField
    );

    let mappedConsequences: Array<BowtieBasicRecord> = [];

    if (
      consequencesRecordPayload &&
      isSuccessResponse(consequencesRecordPayload) &&
      consequencesRecordPayload?.payload?.data?.result?.results.length > 0
    ) {
      const consequences = consequencesRecordPayload.payload.data.result.results;

      mappedConsequences = consequences
        .filter((consequence) => consequence.fields)
        .map<BowtieBasicRecord>((consequence) => {
          const consequencesCaptionField = consequence.fields[mitigatingControls.consequences.captionField];

          return {
            id: consequence && consequence.id,
            uuid: consequence && consequence.id,
            value: consequencesCaptionField as string,
            status: consequence.status,
            linkUrl: consequence?.linkUrl?.length ? consequence.linkUrl : undefined,
          };
        });
    }

    // fetch the (mitigating) control records
    let mappedRecords: Array<BowtieConsequenceRecord> = [];

    if (
      mitigatingControlRecordLinkField &&
      Array.isArray(mitigatingControlRecordLinkField) &&
      mitigatingControlRecordLinkField.length > 0
    ) {
      const recordsPayload = await this.recordService.fetchRecords(
        bowtieConfiguration.forms?.controls?.id,
        mitigatingControlRecordLinkField as number[]
      );

      if (
        isSuccessResponse(recordsPayload) &&
        recordsPayload.payload &&
        recordsPayload.payload.data &&
        recordsPayload.payload.data.result &&
        recordsPayload.payload.data.result.results &&
        recordsPayload.payload.data.result.results.length > 0
      ) {
        const records = recordsPayload.payload.data.result.results;

        const consequencesFields = records
          .filter((record) => record.fields)
          .map((record) => {
            let consequencesRecordLinkField = record.fields[mitigatingControls.consequences.recordLinkField];

            if (
              Array.isArray(consequencesRecordLinkField) &&
              Array.isArray(scenarioRecordLinkField) &&
              consequencesRecordLinkField.length > 0
            ) {
              consequencesRecordLinkField = consequencesRecordLinkField.filter(
                (consequenceField) =>
                  scenarioRecordLinkField.findIndex((scenarioField) => String(scenarioField) === consequenceField) > -1
              );

              return {
                recordId: record.id,
                consequenceId: consequencesRecordLinkField,
              };
            } else {
              return { recordId: null, consequenceId: null };
            }
          });

        mappedRecords = records
          .filter((record) => record.fields)
          .map<BowtieConsequenceRecord>((record) => {
            let effectiveOrNotEffectiveField = null;
            const captionField = record.fields[mitigatingControls.captionField];

            const criticalOrNonCriticalField = record.fields[mitigatingControls.criticalOrNonCriticalField];

            const indexedRecord = record as unknown as Record<string, unknown>;
            if (
              mitigatingControls.effectiveOrNotEffectiveField &&
              indexedRecord[mitigatingControls.effectiveOrNotEffectiveField]
            ) {
              effectiveOrNotEffectiveField = {
                value: {
                  value: indexedRecord[mitigatingControls.effectiveOrNotEffectiveField] as string,
                },
              };
            } else {
              effectiveOrNotEffectiveField = record.fields[mitigatingControls.effectiveOrNotEffectiveField] as {
                value: string;
              };
            }

            const recordConsequencesFields = consequencesFields.find((field) => field.recordId === record.id)
              ?.consequenceId as Array<unknown>;

            const globalField = record.fields['Global'];

            return {
              id: record.id,
              value: captionField as string,
              uuid: record.id,
              status: record.status,
              linkUrl: record?.linkUrl?.length ? record.linkUrl : undefined,
              consequences: recordConsequencesFields
                ? mappedConsequences.filter((consequence) => recordConsequencesFields.includes(String(consequence.id)))
                : [],
              effectiveOrNotEffective:
                effectiveOrNotEffectiveField && isObject(effectiveOrNotEffectiveField)
                  ? (effectiveOrNotEffectiveField.value as { value: string })
                  : undefined,
              criticalOrNonCritical:
                criticalOrNonCriticalField && isObject(criticalOrNonCriticalField)
                  ? criticalOrNonCriticalField.value
                  : undefined,
              global: Boolean(globalField && globalField),
            };
          });
      }
    }

    return mappedRecords && mappedRecords.length > 0
      ? {
          records: mappedRecords,
          consequences: mappedConsequences,
        }
      : { records: [], consequences: mappedConsequences };
  }

  public async fetchPreventativeControlsWithCauses(
    bowtieConfiguration: BowtieConfiguration,
    preventativeControlsRecordLinkField: Array<unknown>,
    preventativeControls: BowtiePreventativeControls,
    scenarioRecordFields: Record<string, unknown>
  ) {
    // fetch the causes
    const scenarioRecordLinkField = scenarioRecordFields[preventativeControls.causes.recordLinkField] as Array<number>;

    const causesRecordPayload = await this.recordService.fetchRecords(
      bowtieConfiguration.forms?.causes?.id,
      scenarioRecordLinkField
    );

    let mappedCauses: Array<BowtieBasicRecord> = [];

    if (
      causesRecordPayload &&
      isSuccessResponse(causesRecordPayload) &&
      causesRecordPayload?.payload?.data?.result?.results.length > 0
    ) {
      const causes = causesRecordPayload.payload.data.result.results;

      mappedCauses = causes
        .filter((cause) => cause.fields)
        .map<BowtieBasicRecord>((cause) => {
          const causesCaptionField = cause.fields[preventativeControls.causes.captionField];

          return {
            id: cause && cause.id,
            uuid: cause && cause.id,
            status: cause.status,
            value: causesCaptionField as string,
            linkUrl: cause?.linkUrl?.length ? cause.linkUrl : undefined,
          };
        });
    }

    // fetch the (preventative) control records
    let mappedRecords: Array<BowtieCauseRecord> = [];

    if (
      preventativeControlsRecordLinkField &&
      Array.isArray(preventativeControlsRecordLinkField) &&
      preventativeControlsRecordLinkField.length > 0
    ) {
      const recordsPayload = await this.recordService.fetchRecords(
        bowtieConfiguration.forms?.controls?.id,
        preventativeControlsRecordLinkField as number[]
      );

      if (
        isSuccessResponse(recordsPayload) &&
        recordsPayload.payload &&
        recordsPayload.payload.data &&
        recordsPayload.payload.data.result &&
        recordsPayload.payload.data.result.results &&
        recordsPayload.payload.data.result.results.length > 0
      ) {
        const records = recordsPayload.payload.data.result.results;

        const causesFields = records
          .filter((record) => record.fields)
          .map<ControlCauseLink>((record) => {
            let causesRecordLinkField = record.fields[preventativeControls.causes.recordLinkField] as Array<string>;

            if (
              Array.isArray(causesRecordLinkField) &&
              Array.isArray(scenarioRecordLinkField) &&
              causesRecordLinkField.length > 0
            ) {
              causesRecordLinkField = causesRecordLinkField.filter(
                (causeField) =>
                  scenarioRecordLinkField.findIndex((scenarioField) => String(scenarioField) === causeField) > -1
              );

              return {
                controlId: record.id || null,
                causeIds: causesRecordLinkField,
              };
            } else {
              return { controlId: null, causeIds: [] };
            }
          });

        mappedRecords = records
          .filter((record) => record.fields)
          .map<BowtieCauseRecord>((record) => {
            let effectiveOrNotEffectiveField = null;
            const captionField = record.fields[preventativeControls.captionField];

            const criticalOrNonCriticalField = record.fields[preventativeControls.criticalOrNonCriticalField];

            const indexedRecord = record as unknown as Record<string, unknown>;
            if (
              preventativeControls.effectiveOrNotEffectiveField &&
              indexedRecord[preventativeControls.effectiveOrNotEffectiveField]
            ) {
              effectiveOrNotEffectiveField = {
                value: {
                  value: indexedRecord[preventativeControls.effectiveOrNotEffectiveField] as string,
                },
              };
            } else {
              effectiveOrNotEffectiveField = record.fields[preventativeControls.effectiveOrNotEffectiveField] as {
                value: string;
              };
            }

            const recordCausesFields = causesFields.find((field) => field.controlId === record.id)?.causeIds;

            const globalField = record.fields['Global'];

            return {
              id: record && record.id,
              uuid: record && record.id,
              value: captionField as string,
              status: record.status,
              linkUrl: record?.linkUrl?.length ? record.linkUrl : undefined,
              causes: recordCausesFields
                ? mappedCauses.filter((cause) => recordCausesFields.includes(String(cause.id)))
                : [],
              effectiveOrNotEffective:
                effectiveOrNotEffectiveField && isObject(effectiveOrNotEffectiveField)
                  ? (effectiveOrNotEffectiveField.value as { value: string })
                  : undefined,
              criticalOrNonCritical:
                criticalOrNonCriticalField && isObject(criticalOrNonCriticalField)
                  ? criticalOrNonCriticalField.value
                  : undefined,
              global: Boolean(globalField),
            };
          });
      }
    }

    return mappedRecords && mappedRecords.length > 0
      ? {
          records: mappedRecords,
          causes: mappedCauses,
        }
      : { records: [], causes: mappedCauses };
  }
}
