import { v4Api as api } from '../base/v4-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTheme: build.query<GetThemeApiResponse, GetThemeApiArg>({
      query: () => ({ url: `/v4/theme` }),
    }),
    getSimpleRecords: build.query<GetSimpleRecordsApiResponse, GetSimpleRecordsApiArg>({
      query: (queryArg) => ({
        url: `/v4/records/simple`,
        params: {
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          filter: queryArg.filter,
          params: queryArg.params,
        },
      }),
    }),
    updateSimpleRecords: build.mutation<UpdateSimpleRecordsApiResponse, UpdateSimpleRecordsApiArg>({
      query: (queryArg) => ({
        url: `/v4/records/simple`,
        method: 'PUT',
        body: queryArg.body,
        params: {
          params: queryArg.params,
        },
      }),
    }),
    createSimpleRecords: build.mutation<CreateSimpleRecordsApiResponse, CreateSimpleRecordsApiArg>({
      query: (queryArg) => ({
        url: `/v4/records/simple`,
        method: 'POST',
        body: queryArg.body,
        params: {
          params: queryArg.params,
        },
      }),
    }),
    patchSimple: build.mutation<PatchSimpleApiResponse, PatchSimpleApiArg>({
      query: (queryArg) => ({
        url: `/v4/records/simple/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.simpleRecordDto,
        params: {
          params: queryArg.params,
        },
      }),
    }),
    getSimpleOptionsForField: build.query<GetSimpleOptionsForFieldApiResponse, GetSimpleOptionsForFieldApiArg>({
      query: (queryArg) => ({
        url: `/v4/fields/${queryArg.fieldId}/options/simple`,
        params: {
          formId: queryArg.formId,
          hierarchyFieldId: queryArg.hierarchyFieldId,
          dependsOn: queryArg.dependsOn,
          recordId: queryArg.recordId,
          keyValue: queryArg.keyValue,
          limit: queryArg.limit,
          filter: queryArg.filter,
          next: queryArg.next,
        },
      }),
    }),
    getEnvironment: build.query<GetEnvironmentApiResponse, GetEnvironmentApiArg>({
      query: () => ({ url: `/v4/schemas/current` }),
    }),
    getRecords2: build.query<GetRecords2ApiResponse, GetRecords2ApiArg>({
      query: (queryArg) => ({
        url: `/v4/forms/${queryArg.formId}/records`,
        params: {
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          filter: queryArg.filter,
          sort: queryArg.sort,
          sort_directive: queryArg.sortDirective,
          order_by: queryArg.orderBy,
          includeTotal: queryArg.includeTotal,
          fetchLinkedRecords: queryArg.fetchLinkedRecords,
          includeAllFields: queryArg.includeAllFields,
          captionValuesBase64encoded: queryArg.captionValuesBase64Encoded,
          globalHierarchyMatch: queryArg.globalHierarchyMatch,
        },
      }),
    }),
    getAddon1: build.query<GetAddon1ApiResponse, GetAddon1ApiArg>({
      query: (queryArg) => ({ url: `/v4/add-ons/${queryArg.id}` }),
    }),
    getAddonMappings: build.query<GetAddonMappingsApiResponse, GetAddonMappingsApiArg>({
      query: (queryArg) => ({
        url: `/v4/add-ons/mappings`,
        params: {
          filter: queryArg.filter,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v4Api };
export type GetThemeApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type GetThemeApiArg = void;
export type GetSimpleRecordsApiResponse = /** status 200 OK */ EntityResponseDtoSimpleRecordFetchResultDto;
export type GetSimpleRecordsApiArg = {
  page?: number;
  pageSize?: number;
  /** Accepts a list of filters.
    The format is `?filter=field:operator:value`, e.g. `deleted:eq:true` to show deleted records.
    
    The valid operators are: 'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'like', 'eq_case_insensitive', 'start_with', 'in', 'is_blank', 'in_with_blank', 'is_archived',.
    - For 'in', 'in_with_blank' operator, the value should be separated by ','.
    - For 'is_blank' and 'is_archived', the value should be boolean value.
    - For 'in_with_blank', the condition will include blank value.
    - The filter key ("field" in above example) can be categorised into 3 categories: record field, hierarchy and pre-defined keywords.
    
    Note that the `formId` filter supports the multi-value 'in' operator, meaning that records can be fetched for multiple forms.
    
    There are some rules for filter parameter: - No filters: return all records the user has access to view
    - Filter by form id: return all records the user has access to view that match any of the form ids
    - Filter by keyword: return all records the user has access to view that match the specified keyword value. Since keywords are consistent across all forms, only include records where there is a match. Exclude all others.
    For `lastModifiedDateRange` keyword, it will return records which last modified date is in specified date range, support values: `thisMonth, lastThreeMonths, lastSixMonths, thisYear, all`
    - Filter by a specific form field that only exists in one form: return all records the user has access to view that match the form field filter. For some option field type, like COMBOBOX, OPTIONGROUP, MULTISELECTFIELD, etc, it support "in" operator, the value can be its field option id or field option value, if filtered by field option value, each value should be wrapped with "", like Priority:in:"High","Low"
     */
  filter?: 'id, status, workflowStepId, docNo, author, creationDate, lastModifiedDate(deprecated, replaced by lastChange), lastChange, dbLastChange, deleted, archivedStep, excludeNonRecurs, lastModifiedDateRange(deprecated, replaced by lastChangeRange), lastChangeRange, authorId';
  /** This parameter supports the following options:
    
    1. `holdNotifications` - this controls whether to send notifications to affected users. These notifications cannot be sent later. Default is false.
    1. `validation` =
        - not specified (default) - allow records with unrecognised options to be imported, but leave the field blank when an unrecognised option is specified.
        - `strict` - validate to ensure submitted options exist. Do not import the record if the option does not exist. Instead, return a validation failure message ('No form found for record id: xxx'), and include details of the field and value that is not recognised.
        - `create` - create unrecognised options for all field types. Leave it up to the end user to manually archive the relevant ones.
        - `createAndArchive` - Auto create unrecognised options and automatically mark them as archived after they are created. If more records are uploaded with the same option, the existing archived value will be reused.
        > _NOTE:_ Some field types allow end users to add new values. For these fields, unrecognised options will automatically be added regardless of validation type.
    1. `fetchLinkedRecords` - this controls whether to fetch the full json object of linked records, or just the linked record id. Default is false.
    1. `createNew` - when updating records, this controls whether to create new records if the record is not found using the specified `sourceId` and `id`. This only happens when the sourceId has been specified in payload. Default is true.
    1. `keyType` - this controls which type of key will be used for fields. If the value is `id` it will use the field's id as the key, otherwise it will use the field's caption.
     */
  params: {
    [key: string]: string;
  };
};
export type UpdateSimpleRecordsApiResponse = /** status 200 OK */ EntityResponseDtoListRecordResponseDto;
export type UpdateSimpleRecordsApiArg = {
  /** This parameter supports the following options:
    
    1. `holdNotifications` - this controls whether to send notifications to affected users. These notifications cannot be sent later. Default is false.
    1. `validation` =
        - not specified (default) - allow records with unrecognised options to be imported, but leave the field blank when an unrecognised option is specified.
        - `strict` - validate to ensure submitted options exist. Do not import the record if the option does not exist. Instead, return a validation failure message ('No form found for record id: xxx'), and include details of the field and value that is not recognised.
        - `create` - create unrecognised options for all field types. Leave it up to the end user to manually archive the relevant ones.
        - `createAndArchive` - Auto create unrecognised options and automatically mark them as archived after they are created. If more records are uploaded with the same option, the existing archived value will be reused.
        > _NOTE:_ Some field types allow end users to add new values. For these fields, unrecognised options will automatically be added regardless of validation type.
    1. `overwriteHistoryField` - determines whether to clear and replace HISTORY field contents with the current payload, or to append content to the existing HISTORY value (default behaviour).Default is false.
    1. `applyDefaultValues` - automatically sets default values on fields when they are omitted or set to blank. Default is false.
    1. `setDerivedValues` - automatically calculates and sets values of fields whose default values are derived from other field values. Default is false.
    1. `createNew` - when updating records, this controls whether to create new records if the record is not found using the specified `sourceId` and `id`. This only happens when the sourceId has been specified in payload. Default is true.
    1. `keyType` - this controls which type of key will be used for fields. If the value is `id` it will use the field's id as the key, otherwise it will use the field's caption.
    1. `hierarchyValueType` - this controls which type of key will be used for hierarchy values. If the value is `id` it will use the hierarchy value id as the key, otherwise it will use the hierarchy value caption.
     */
  params: {
    [key: string]: string;
  };
  body: SimpleRecordDto[];
};
export type CreateSimpleRecordsApiResponse = /** status 200 OK */ EntityResponseDtoListRecordResponseDto;
export type CreateSimpleRecordsApiArg = {
  /** This parameter supports the following options:
    
    1. `holdNotifications` - this controls whether to send notifications to affected users. These notifications cannot be sent later. Default is false.
    1. `validation` =
        - not specified (default) - allow records with unrecognised options to be imported, but leave the field blank when an unrecognised option is specified.
        - `strict` - validate to ensure submitted options exist. Do not import the record if the option does not exist. Instead, return a validation failure message ('No form found for record id: xxx'), and include details of the field and value that is not recognised.
        - `create` - create unrecognised options for all field types. Leave it up to the end user to manually archive the relevant ones.
        - `createAndArchive` - Auto create unrecognised options and automatically mark them as archived after they are created. If more records are uploaded with the same option, the existing archived value will be reused.
        > _NOTE:_ Some field types allow end users to add new values. For these fields, unrecognised options will automatically be added regardless of validation type.
    1. `overwriteHistoryField` - determines whether to clear and replace HISTORY field contents with the current payload, or to append content to the existing HISTORY value (default behaviour).Default is false.
    1. `applyDefaultValues` - automatically sets default values on fields when they are omitted or set to blank. Default is false.
    1. `setDerivedValues` - automatically calculates and sets values of fields whose default values are derived from other field values. Default is false.
    1. `keyType` - this controls which type of key will be used for fields. If the value is `id` it will use the field's id as the key, otherwise it will use the field's caption.
    1. `hierarchyValueType` - this controls which type of key will be used for hierarchy values. If the value is `id` it will use the hierarchy value id as the key, otherwise it will use the hierarchy value caption.
     */
  params: {
    [key: string]: string;
  };
  body: SimpleRecordDto[];
};
export type PatchSimpleApiResponse = /** status 200 OK */ EntityResponseDtoLong;
export type PatchSimpleApiArg = {
  id: number;
  /** This parameter supports the following options:
    
    1. `holdNotifications` - this controls whether to send notifications to affected users. These notifications cannot be sent later. Default is false.
    1. `validation` =
        - not specified (default) - allow records with unrecognised options to be imported, but leave the field blank when an unrecognised option is specified.
        - `strict` - validate to ensure submitted options exist. Do not import the record if the option does not exist. Instead, return a validation failure message ('No form found for record id: xxx'), and include details of the field and value that is not recognised.
        - `create` - create unrecognised options for all field types. Leave it up to the end user to manually archive the relevant ones.
        - `createAndArchive` - Auto create unrecognised options and automatically mark them as archived after they are created. If more records are uploaded with the same option, the existing archived value will be reused.
        > _NOTE:_ Some field types allow end users to add new values. For these fields, unrecognised options will automatically be added regardless of validation type.
    1. `overwriteHistoryField` - determines whether to clear and replace HISTORY field contents with the current payload, or to append content to the existing HISTORY value (default behaviour).Default is false.
    1. `applyDefaultValues` - automatically sets default values on fields when they are set to blank. Default is false.
    1. `setDerivedValues` - automatically calculates and sets values of fields whose default values are derived from other field values. Default is false.
    1. `appendLinkedRecords` - when true, linked records will be appended to existing linked records instead of replacing them. Default is false.
    1. `keyType` - this controls which type of key will be used for fields. If the value is `id` it will use the field's id as the key, otherwise it will use the field's caption.
    1. `hierarchyValueType` - this controls which type of key will be used for hierarchy values. If the value is `id` it will use the hierarchy value id as the key, otherwise it will use the hierarchy value caption.
     */
  params: {
    [key: string]: string;
  };
  simpleRecordDto: SimpleRecordDto;
};
export type GetSimpleOptionsForFieldApiResponse = /** status 200 OK */ EntityCursorResponseDtoFieldOptionDtoObject;
export type GetSimpleOptionsForFieldApiArg = {
  fieldId: number;
  /** formId is mandatory */
  formId?: number;
  /** hierarchyFieldId is mandatory if lookup type is 'From a Record matched by a hierarchy value' */
  hierarchyFieldId?: number;
  /** The parent option id if applicable */
  dependsOn?: number;
  /** recordId is mandatory if lookup type is 'From a Linked Record' */
  recordId?: number;
  /** keyValue is mandatory if lookup type is 'From a Record matched by a field value', if lookup from any OPTION field(e.g. COMBOBOX, MULTISELECTFIELD, etc), keyValue will be option id */
  keyValue?: string;
  /** The item count returned per page, apply value -1 to get all items */
  limit?: number;
  /** Support caption and archived filter, e.g. filter=caption:eq:xyz, the valid operators are: 'eq', 'neq', 'like', 'start_with', 'eq_case_insensitive', 'eq_case_insensitive', for 'eq', 'like', 'start_with', they are case insensitive. */
  filter?: string[];
  /** If total options are more than one page, 'next' will be returned in result, specify  cursorNext with this value will return next page. 'next' will not be returned if no next page */
  next?: string;
};
export type GetEnvironmentApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type GetEnvironmentApiArg = void;
export type GetRecords2ApiResponse = /** status 200 OK */ EntityResponseDtoRecordFetchResultDtoRecordDto;
export type GetRecords2ApiArg = {
  formId: number;
  page?: number;
  pageSize?: number;
  /** Accepts a list of filters.
    The format is `?filter=field:operator:value`, e.g. `deleted:eq:true` to show deleted records.
    
    The valid operators are: 'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'like', 'eq_case_insensitive', 'start_with', 'in', 'is_blank', 'in_with_blank', 'is_archived',.
    - For 'in', 'in_with_blank' operator, the value should be separated by ','.
    - For 'is_blank' and 'is_archived', the value should be boolean value.
    - For 'in_with_blank', the condition will include blank value.
    - The filter key ("field" in above example) can be categorised into 3 categories: record field, hierarchy and pre-defined keywords.
    
    For all categories, it would be using their captions or id as the filter key(old format), also support new format(`filter={propertyType}[:{typeIdentifier}][:{property}]:{operator}:{value}`) as below:
    - For normal field, it support `FIELD`:{fieldId} as filter key, e.g. FIELD:1:eq:xyz;
    - For record link field, it support `RECORDLINK`:{recordLinkFieldId} + linked field filter format as filter key, e.g. RECORDLINK:99:FIELD:1:eq:xyz;
    - For reversed record link field, it support `REVERSEDRECORDLINK`:{recordLinkFieldId} + linked field filter format as filter key, e.g. REVERSEDRECORDLINK:99:FIELD:1:eq:xyz;
    - For hierarchy, it support `HIERARCHY`:{hierarchyTypeId} as filter key, e.g. HIERARCHY:1:eq:xyz;
    - For workflow step history, it support filter key is `WORKFLOWSTEP`:{workflowStepId}:TRANSITIONDATE as filter key, e.g. WORKFLOWSTEP:1:TRANSITIONDATE:gt:2024-02-14T00:00:00.000;
    - For pre-defined keywords, please refer to below. It support `KEYWORD`:{keyword} as filter key, e.g. KEYWORD:docNo:eq:1;
    - For some filters, it can filter by either id or caption, like COMBOBOX, keyword status, etc, it can add `ID` or `CAPTION` property to make filter more clearly, e.g. FIELD:1:ID:in:1,2,3, FIELD:1:CAPTION:in:x,y,z, KEYWORD:status:ID:1,2, KEYWORD:status:caption:in:Open,Closed;
    - Operators start with 'id'(id_in, id_in_with_blank, id_eq, id_neq) will be deprecated, if want to filter by id, please specify `ID` property as above.
    
    The `filter` parameter can be used as follows:
    - No filters: return all records the user has access to view.
    - Filter by keyword: returns all records the user has access to view that match the specified keyword value. Since keywords are consistent across all forms, only matching records are included.
    For `lastModifiedDateRange` keyword, it will return records which last modified date is in specified date range, support values: `thisMonth, lastThreeMonths, lastSixMonths, thisYear, all`
    - Filter by field: return all records the user has access to that match the form field filter. Some field types (e.g. COMBOBOX, OPTIONGROUP, MULTISELECTFIELD, etc,) support the `in` operator, and the value can either be the field option id or field option caption (Please specify `ID` or `CAPTION` property in field key)
     */
  filter?: 'id, status, workflowStepId, docNo, author, creationDate, lastModifiedDate(deprecated, replaced by lastChange), lastChange, dbLastChange, deleted, archivedStep, excludeNonRecurs, lastModifiedDateRange(deprecated, replaced by lastChangeRange), lastChangeRange, authorId';
  /** Property name to sort by. To sort by multiple properties, separate sort parameter should be used for each property. The order of the properties is significant and values are case insensitive for string properties. By default the order is ascending (`-` prefix for descending, e.g. `-propertyName`). This parameter is mutually exclusive with sort_directive and will throw an error if both are used.
    Besides the field names you can sort by docNo, lastChange, dbLastChange, creationDate and status. When using these 'special fields' be aware that if there's a field in the form with the names mentioned before, the field of the form has priority */
  sort?: string[];
  /** Sort defining "directive". To define multiple directives, separate parameter should be used for each directive.
    The order of the directives is significant. By default the order is ascending, and case insensitive for string properties.
    For descending sort `-` prefix is used (e.g. `-FIELD:100`).
    This parameter is mutually exclusive with sort and will throw an error if both are used.
    The format is `sort_directive={propertyType}[:{typeIdentifier}][:{property}]`
    The directives follow a special notation that allows us to reference different record entities, namely FIELD, KEYWORD, HIERARCHY, QUESTIONNAIRE, etc.
    - For normal field, the format is `FIELD`:{fieldId}; The default reference is FIELD so in this case FIELD:100 and 100 would be equivalent and would both mean sort by field with ID 100
    - For keyword, the new format is `KEYWORD`:{keyword}. The legacy format `KEYWORD`:{keyword Id}. To sort by a keyword with ID 100 you would need to pass KEYWORD:100 as a directive and to make that descending you would pass -KEYWORD:100. The new format will be KEYWORD:status
    - For HIERARCHY, the format is `HIERARCHY`:{hierarchy type id}; The legacy format `HIERARCHY_TYPE`:{hierarchy type id}
    - For QUESTIONNAIRE field, the format is `QUESTIONNAIRE`:{fieldId}:{QUESTIONNAIRE type}; `QUESTIONNAIRE type` can be one of TOTAL_SCORE, AVERAGE_SCORE, MAX_SCORE_POSSIBLE and PERCENT_SCORE. e.g. QUESTIONNAIRE:1:TOTAL_SCORE will sort by TOTAL_SCORE.
    - For GRAND SCORE field, the format is `GRANDSCORE`:{fieldId}:{GRANDSCORE type}; `GRANDSCORE type` can be one of TOTAL_SCORE, MAX_POSSIBLE and PERCENTAGE. e.g. GRANDSCORE:1:PERCENTAGE will sort by PERCENTAGE.
     */
  sortDirective?: string[];
  /** The sort order of the properties defined in sort parameter. */
  orderBy?: 'DESC' | 'ASC';
  includeTotal?: boolean;
  /** Whether to fetch the complete JSON data of linked records, or only the record ids. */
  fetchLinkedRecords?: boolean;
  /** Whether to include all record field value even if the field is removed or value is blank, or only the active field and value has been assigned. */
  includeAllFields?: boolean;
  /** Specifies whether filter values should be treated as base64-encoded. Default is false. If true, it will consider filter value as a base64-encoded string, and it will be decoded in the backend. If you wish to encode filter values, please encode values individually (not the whole filter). e.g. in `filter=field:1:caption:in:a,b,c` the values 'a', 'b', and 'c' need to be encoded individually and separated with commas, i.e. `filer=field:1:caption:in:YQ==,Yg==,Yw==`.
   */
  captionValuesBase64Encoded?: boolean;
  /** Global hierarchy filter match type. May be `all` or `any`. Default is all.  */
  globalHierarchyMatch?: string;
};
export type GetAddon1ApiResponse = /** status 200 OK */ EntityResponseDtoAddonResultDto;
export type GetAddon1ApiArg = {
  id: number;
};
export type GetAddonMappingsApiResponse = /** status 200 OK */ EntityResponseDtoListAddonMappingDto;
export type GetAddonMappingsApiArg = {
  /** Supported filters:<ul><li>`name` - addon name</li><li>`display_type` - addon display type (which determines where the addon button is being displayed).<br/>Possible values: `RECORD_LIST`, `RECORD` and `RECORD_MORE`.</li></ul> */
  filter?: string[];
};
export type ResponseMessageDto = {
  index?: number;
  code?: string;
  key?: string;
  message?: string;
};
export type Validation = {
  /** the errors that occured while attempting the requested operation */
  errors?: string[];
  /** the warnings that occured while attempting the requested operation */
  warnings?: string[];
  /** the new format errors that occured while attempting the requested operation */
  responseErrors?: ResponseMessageDto[];
  /** the new format warnings that occured while attempting the requested operation */
  responseWarnings?: ResponseMessageDto[];
};
export type UserDto = {
  id?: number;
  value?: string;
  managerId?: number;
  managerValue?: string;
  archived?: boolean;
};
export type AttachmentDto = {
  id?: number;
  name?: string;
  person?: UserDto;
  recordId?: number;
  lastModified?: string;
  sourceId?: string;
  deleted?: boolean;
  fileKey?: string;
  url?: string;
};
export type HierarchyFieldDtoString = {
  id?: number;
  hierarchyTypeId?: number;
  hierarchyTypeCaption?: string;
  parentId?: number;
  caption?: string;
  longitude?: number;
  latitude?: number;
  geofenceEnabled?: boolean;
  geofenceRadius?: number;
  archived?: boolean;
  lastChange?: string;
  deleted?: boolean;
  hierarchyFieldParentCaption?: string;
};
export type SimpleRecordDto = {
  id?: number;
  sourceId?: string;
  moduleId?: number;
  moduleName?: string;
  viewId?: string;
  formId?: number;
  formName?: string;
  status?: string;
  docNo?: number;
  author?: UserDto;
  accountable?: UserDto[];
  creationDate?: string;
  lastModifiedDate?: string;
  lastChange?: string;
  dbLastChange?: string;
  linkUrl?: string;
  vikingLinkUrl?: string;
  deleted?: boolean;
  version?: number;
  displayText?: string;
  title?: string;
  calendarViewDate?: string;
  files?: string[];
  images?: string[];
  deletedFiles?: string[];
  mobile?: {
    [key: string]: string;
  };
  readAccessRoles?: string[];
  childRecordIds?: number[];
  workflowStepHistory?: {
    [key: string]: string;
  };
  attachmentInfo?: AttachmentDto[];
  sourceSystem?: 'ONLINE_LEARNING' | 'DOMINO';
  /** Instead of `hierarchies` hierarchyDetails must be used */
  hierarchies?: {
    [key: string]: object;
  };
  hierarchyDetails?: {
    [key: string]: object;
  };
  fields?: {
    [key: string]: string;
  };
  hierarchyValues?: {
    [key: string]: HierarchyFieldDtoString;
  };
  template?: SimpleRecordDto;
};
export type SimpleRecordFetchResultDto = {
  apiVersion?: string;
  name?: string;
  page?: number;
  hits?: number;
  results?: SimpleRecordDto[];
};
export type EntityResponseDtoSimpleRecordFetchResultDto = {
  validation?: Validation;
  result?: SimpleRecordFetchResultDto;
};
export type RecordResponseDto = {
  success?: boolean;
  id?: number;
  sourceId?: string;
  error?: string;
  warnings?: string[];
};
export type EntityResponseDtoListRecordResponseDto = {
  validation?: Validation;
  result?: RecordResponseDto[];
};
export type EntityResponseDtoLong = {
  validation?: Validation;
  result?: number;
};
export type FieldOptionDtoObject = {
  id?: number;
  fieldId?: number;
  caption?: string;
  abbreviationCaption?: string;
  parentFieldOptionId?: number;
  defaultOption?: boolean;
  archived?: boolean;
  position?: number;
  color?: string;
};
export type EntityCursorResponseDtoFieldOptionDtoObject = {
  validation?: Validation;
  /** The cursor to include to get the next page, or null if there are no more pages */
  next?: string;
  items?: FieldOptionDtoObject[];
};
export type TranslationDto = {
  id?: number;
  localeId?: number;
  value?: string;
};
export type TranslationUnitDto = {
  id?: number;
  description?: string;
  translations?: TranslationDto[];
};
export type HierarchyFieldDtoTranslationUnitDto = {
  id?: number;
  hierarchyTypeId?: number;
  hierarchyTypeCaption?: TranslationUnitDto;
  parentId?: number;
  caption?: TranslationUnitDto;
  longitude?: number;
  latitude?: number;
  geofenceEnabled?: boolean;
  geofenceRadius?: number;
  archived?: boolean;
  lastChange?: string;
  deleted?: boolean;
  hierarchyFieldParentCaption?: TranslationUnitDto;
};
export type HierarchyDetailsDto = {
  caption?: string;
  latitude?: number;
  longitude?: number;
};
export type HierarchyFieldDtoHierarchyDetailsDto = {
  id?: number;
  hierarchyTypeId?: number;
  hierarchyTypeCaption?: HierarchyDetailsDto;
  parentId?: number;
  caption?: HierarchyDetailsDto;
  longitude?: number;
  latitude?: number;
  geofenceEnabled?: boolean;
  geofenceRadius?: number;
  archived?: boolean;
  lastChange?: string;
  deleted?: boolean;
  hierarchyFieldParentCaption?: HierarchyDetailsDto;
};
export type RecordFieldDtoObject = {
  id?: number;
  name?: string;
  fieldId?: number;
  type?:
    | 'API_QUERY'
    | 'CHECKBOX'
    | 'CHECKBOX_CAPTION'
    | 'CHECKBOX_DEFAULT'
    | 'CHECKBOX_ICON'
    | 'CLONERECORD'
    | 'COMBOBOX'
    | 'DATEFIELD'
    | 'DOUBLEFIELD'
    | 'EMAIL_THIS_BUTTON'
    | 'GEOLOCATION'
    | 'GRAND_SCORE'
    | 'HISTORY'
    | 'INTFIELD'
    | 'INVITE_BUTTON'
    | 'LABEL'
    | 'LINK'
    | 'MULTISELECTCHECKBOX'
    | 'MULTISELECTFIELD'
    | 'MULTISELECTPERSONFIELD'
    | 'NUMERIC_QUESTIONNAIRE'
    | 'OPTION_BUILDER'
    | 'OPTIONGROUP'
    | 'PDF_EXPORT'
    | 'PERSONFIELD'
    | 'READERS'
    | 'RECORDLINK'
    | 'REVERSE_RECORD_LINK'
    | 'RICHTEXTAREA'
    | 'RISKRATING'
    | 'SIGNATURECAPTURE'
    | 'SUMMARY_RECORD_LINK'
    | 'TEXTAREA'
    | 'TEXTFIELD'
    | 'TEXTUAL_QUESTIONNAIRE'
    | 'TIMEFIELD'
    | 'TRAINING'
    | 'TWINCOLUMNSELECT'
    | 'TWINCOLUMNSELECTPERSONFIELD'
    | 'USER_RECORDLINK'
    | 'WORD_EXPORT';
  value?: object;
  result?: {
    [key: string]: object;
  };
};
export type RecordDto = {
  id?: number;
  sourceId?: string;
  moduleId?: number;
  moduleName?: string;
  viewId?: string;
  formId?: number;
  formName?: string;
  status?: string;
  docNo?: number;
  author?: UserDto;
  accountable?: UserDto[];
  creationDate?: string;
  lastModifiedDate?: string;
  lastChange?: string;
  dbLastChange?: string;
  linkUrl?: string;
  vikingLinkUrl?: string;
  deleted?: boolean;
  version?: number;
  displayText?: string;
  title?: string;
  calendarViewDate?: string;
  files?: string[];
  images?: string[];
  deletedFiles?: string[];
  mobile?: {
    [key: string]: string;
  };
  readAccessRoles?: string[];
  childRecordIds?: number[];
  workflowStepHistory?: {
    [key: string]: string;
  };
  attachmentInfo?: AttachmentDto[];
  sourceSystem?: 'ONLINE_LEARNING' | 'DOMINO';
  hierarchies?: HierarchyFieldDtoTranslationUnitDto[];
  hierarchyDetails?: HierarchyFieldDtoHierarchyDetailsDto[];
  fields?: RecordFieldDtoObject[];
};
export type RecordFetchResultDtoRecordDto = {
  apiVersion?: string;
  name?: string;
  page?: number;
  hits?: number;
  total?: number;
  results?: RecordDto[];
};
export type EntityResponseDtoRecordFetchResultDtoRecordDto = {
  validation?: Validation;
  result?: RecordFetchResultDtoRecordDto;
};
export type AddonDto = {
  id?: number;
  caption?: string;
  target?: string;
  jsonObject?: string;
  url?: string;
  icon?: string;
  displayType?: string;
  allModules?: boolean;
};
export type AddonResultDto = {
  success?: boolean;
  errorMessage?: string;
  validationStatus?: Validation;
  result?: AddonDto;
};
export type EntityResponseDtoAddonResultDto = {
  validation?: Validation;
  result?: AddonResultDto;
};
export type AddonMappingDto = {
  id?: number;
  caption?: string;
  displayType?: string;
  allModules?: boolean;
  allForms?: boolean;
  moduleId?: number;
  formId?: number;
};
export type EntityResponseDtoListAddonMappingDto = {
  validation?: Validation;
  result?: AddonMappingDto[];
};
export const {
  useGetThemeQuery,
  useLazyGetThemeQuery,
  useGetSimpleRecordsQuery,
  useLazyGetSimpleRecordsQuery,
  useUpdateSimpleRecordsMutation,
  useCreateSimpleRecordsMutation,
  usePatchSimpleMutation,
  useGetSimpleOptionsForFieldQuery,
  useLazyGetSimpleOptionsForFieldQuery,
  useGetEnvironmentQuery,
  useLazyGetEnvironmentQuery,
  useGetRecords2Query,
  useLazyGetRecords2Query,
  useGetAddon1Query,
  useLazyGetAddon1Query,
  useGetAddonMappingsQuery,
  useLazyGetAddonMappingsQuery,
} = injectedRtkApi;
